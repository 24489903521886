import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './BciBalanceChart.css';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const BciBalanceChart = ({ data }) => {
  const [selectedRange, setSelectedRange] = useState('ALL');

  const hasNonZeroData = data?.some(item => 
    item.btc_balance !== 0 || 
    item.btc_per_share !== 0 || 
    item.usd_value_of_btc_holdings !== 0
  );

  const filterDataByRange = (data, range) => {
    if (!range || range === 'ALL') return data;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - range);
    return data.filter(item => new Date(item.date) >= cutoffDate);
  };

  const getChartOption = () => {
    if (!Array.isArray(data) || data.length === 0 || !hasNonZeroData) {
      return {
        title: {
          text: 'No BTC Holdings Data Available',
          textStyle: {
            color: '#666',
            fontSize: 14,
            fontWeight: 'normal'
          },
          left: 'center',
          top: 'center'
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      };
    }

    const filteredData = filterDataByRange(
      data,
      TIME_RANGES.find(r => r.label === selectedRange)?.days
    );

    const SCALE_FACTOR = 1000000000;

    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          let result = params[0].axisValue + '<br/>';
          params.forEach(param => {
            let value = param.value;
            if (param.seriesName === 'BTC Per Share') {
              value = (value / SCALE_FACTOR).toFixed(8);
            } else if (param.seriesName === 'USD Value of BTC Holdings') {
              value = `$${(value / 1000000).toFixed(2)}M`;
            }
            result += `${param.marker} ${param.seriesName}: ${value}<br/>`;
          });
          return result;
        }
      },
      legend: {
        data: ['BTC Per Share', 'USD Value of BTC Holdings', 'BTC Holdings'],
        top: 0
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '40px',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(item => item.date),
        boundaryGap: false
      },
      yAxis: [
        {
          type: 'value',
          name: 'USD Value',
          position: 'left',
          axisLabel: {
            formatter: value => `$${(value / 1000000).toFixed(0)}M`
          }
        },
        {
          type: 'value',
          name: 'BTC',
          position: 'right',
          axisLabel: {
            formatter: value => value.toFixed(0)
          }
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          start: 0,
          end: 100
        }
      ],
      series: [
        {
          name: 'BTC Per Share',
          type: 'line',
          data: filteredData.map(item => item.btc_per_share * SCALE_FACTOR),
          smooth: true,
          symbol: 'none',
          lineStyle: { color: '#1a73e8', width: 2 },
          yAxisIndex: 1
        },
        {
          name: 'USD Value of BTC Holdings',
          type: 'line',
          data: filteredData.map(item => item.usd_value_of_btc_holdings),
          smooth: true,
          symbol: 'none',
          lineStyle: { color: '#34a853', width: 2 },
          yAxisIndex: 0
        },
        {
          name: 'BTC Holdings',
          type: 'line',
          data: filteredData.map(item => item.btc_balance),
          smooth: true,
          symbol: 'none',
          lineStyle: { color: '#fbbc05', width: 2 },
          yAxisIndex: 1
        }
      ]
    };
  };

  return (
    <div className="stock-bci-balance-container">
      <div className="stock-bci-balance-header">
        <h3 className="stock-bci-balance-title">BTC Balance History</h3>
        {hasNonZeroData && (
          <div className="stock-bci-balance-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`stock-bci-balance-range-button ${
                  selectedRange === range.label ? 'active' : ''
                }`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        )}
      </div>
      <ReactECharts 
        option={getChartOption()} 
        style={{ height: '400px' }}
        className="stock-bci-balance-chart"
      />
    </div>
  );
};

export default BciBalanceChart; 