// For local development, use the following line:
const apiBaseUrl = 'https://user-mgmt.helloswx.workers.dev';

// For production, use the following line with your domain name:
//const apiBaseUrl = 'https://user-mgmt.yourdomain.com';

// Or, if you're running the user-mgmt worker under a route on the same domain as this front end:
// const apiBaseUrl = `${window.location.protocol}//${window.location.host}/user-api`;

export function callLoginApi(endpoint, data = null) {
    const url = apiBaseUrl + endpoint;
    const options = {
        credentials: 'include',
        crossDomain: true,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };
    
    if (data) {
        options.body = JSON.stringify(data);
        options.method = 'POST';
    }

    return fetch(url, options)
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Network response was not ok');
            }
            return response.json();
        });
}

// 添加 Google 登录相关的函数
export function initiateGoogleLogin() {
    window.location.href = `${apiBaseUrl}/auth/google`;
}
// 专门用于处理 Google 登录回调的函数
export function handleGoogleAuthCallback() {
    return new Promise((resolve, reject) => {
        const tryLoadUser = (retries = 3) => {
            const url = apiBaseUrl + '/load-user';
            fetch(url, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-Auth-Type': 'google'
                }
            })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Network response was not ok');
                }
                return response.json();
            })
            .then(resolve)
            .catch(error => {
                console.log(`Google auth attempt failed (${retries} left):`, error);
                if (retries > 0) {
                    setTimeout(() => tryLoadUser(retries - 1), 1000);
                } else {
                    reject(error);
                }
            });
        };
        
        setTimeout(() => tryLoadUser(), 500);
    });
}

