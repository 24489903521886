import { callLoginApi } from '../login_api';
import { getSubscriptionInfo } from './stripe_api';
import { CacheService } from '../services/cache_service';

export const setupAuthRefresh = (store) => {
  const checkAuthStatus = async () => {
    const state = store.getState();
    const { tokenExpiry, isLoggedIn } = state.auth;

    if (isLoggedIn && tokenExpiry) {
      if (new Date(tokenExpiry).getTime() - Date.now() < 5 * 60 * 1000) {
        try {
          const userData = await callLoginApi('/refresh-token');
          store.dispatch({ type: 'UPDATE_AUTH_TOKEN', payload: userData });
        } catch (error) {
          store.dispatch({ type: 'SET_LOGGED_OUT' });
        }
      }
    }
  };

  setInterval(checkAuthStatus, 60 * 1000);
};

export const setupSubscriptionSync = (store) => {
  const syncSubscription = async () => {
    const state = store.getState();
    const { userData, isLoggedIn } = state.auth;

    if (isLoggedIn && userData?.userid) {
      try {
        // 检查缓存是否过期
        const cachedData = CacheService.get('subscription');
        if (cachedData) return;

        const subscriptionData = await getSubscriptionInfo(userData.userid);
        store.dispatch({ 
          type: 'UPDATE_SUBSCRIPTION_DATA', 
          payload: subscriptionData 
        });
        CacheService.set('subscription', subscriptionData);
      } catch (error) {
        console.error('Failed to sync subscription:', error);
      }
    }
  };

  // 每5分钟同步一次
  setInterval(syncSubscription, 5 * 60 * 1000);
  // 立即执行一次
  syncSubscription();
};

export const AuthService = {
  async refreshSession() {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await fetch('/api/refresh-token', {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) throw new Error('Token refresh failed');

      const { newToken, expiresIn } = await response.json();
      
      // 更新 token
      localStorage.setItem('token', newToken);
      localStorage.setItem('tokenExpiry', Date.now() + expiresIn);

      return true;
    } catch (error) {
      console.error('Session refresh failed:', error);
      return false;
    }
  },

  isSessionValid() {
    const tokenExpiry = localStorage.getItem('tokenExpiry');
    return tokenExpiry && Date.now() < parseInt(tokenExpiry);
  }
}; 