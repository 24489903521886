import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from './PageLayout';
import { PRODUCTS } from '../config';
import { createCheckoutSession, getSubscriptionInfo, activateOneDayPass, stripePromise } from '../services/stripe_api';
import './PricingPage.css';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

const PricingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userData = useSelector(state => state.auth.userData);
  const subscription = useSelector(state => state.auth.subscription);

  const handleSubscribe = async (priceId) => {
    try {
      if (!userData?.userid) {
        navigate('/login');
        return;
      }

      setLoading(true);
      setError(null);

      const checkoutSession = await createCheckoutSession(
        priceId,
        userData.userid,
        'monthly'
      );

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error(t('errors.stripeLoadFailed'));
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.id
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (err) {
      console.error('Subscribe error:', err);
      setError(t(err.message) || t('errors.paymentFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleOneDayPass = async () => {
    try {
      if (!userData?.userid) {
        toast.error('Please login first');
        navigate('/login');
        return;
      }

      setLoading(true);
      
      const response = await activateOneDayPass(userData.userid);
      console.log('One-day pass response:', response);
      
      if (response?.success) {
        const newSubscriptionData = await getSubscriptionInfo(userData.userid);
        dispatch({ 
          type: 'UPDATE_SUBSCRIPTION_DATA', 
          payload: newSubscriptionData 
        });

        toast.success('One-day pass activated successfully');
        navigate('/profile');
      }
    } catch (err) {
      console.error('One-day pass activation error:', err);
      const errorMessage = err.response?.data?.error || // axios 错误响应
                          err.error || // 直接错误对象
                          err.message || // 标准 Error 对象
                          'Activation failed, please try again'; // 默认错误消息
      
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscount = (monthlyPrice, yearlyPrice) => {
    if (monthlyPrice === 0 || yearlyPrice === 0) return 0;
    const monthlyTotal = monthlyPrice * 12;
    const yearlyTotal = yearlyPrice;
    const discount = ((monthlyTotal - yearlyTotal) / monthlyTotal) * 100;
    return Math.round(discount);
  };

  return (
    <PageLayout>
      <div className="pricing-page">
        <Toaster 
          position="top-center"
          toastOptions={{
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
              padding: '16px 32px',
              fontSize: '18px',
              maxWidth: '600px',
              marginTop: '100px',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            },
            success: {
              iconTheme: {
                primary: '#4CAF50',
                secondary: '#fff',
              },
              style: {
                background: '#1a472a',
                border: '2px solid #2e7d32',
              }
            },
            error: {
              iconTheme: {
                primary: '#ff4b4b',
                secondary: '#fff',
              },
              style: {
                background: '#442726',
                border: '2px solid #d32f2f',
              }
            },
          }}
        />
        
        <div className="content">
          <h1 className="pricing-title">Pricing</h1>
          <p className="pricing-subtitle">
            A 30-Day FREE trial is included for new monthly members only!
          </p>

          <div className="pricing-plans">
            {/* Monthly Plan */}
            <div className="plan">
              <h2 className="plan-header">Monthly Plan</h2>
              <div className="plan-price">
                <span className="price-amount">$20</span>
                <span className="price-period">/mo</span>
              </div>
              <button 
                className="subscribe-button" 
                onClick={() => handleSubscribe(PRODUCTS[0].monthlyPriceId)}
                disabled={loading}
              >
                Subscribe
              </button>
            </div>

            {/* Annual Plan */}
            <div className="plan">
              <div className="save-badge">Save 17%</div>
              <h2 className="plan-header">Annual Plan</h2>
              <div className="plan-price">
                <div className="original-price">$240/yr</div>
                <span className="price-amount">$200</span>
                <span className="price-period">/yr</span>
              </div>
              <div className="savings-text">Savings: $40</div>
              <button 
                className="subscribe-button" 
                onClick={() => handleSubscribe(PRODUCTS[0].yearlyPriceId)}
                disabled={loading}
              >
                Subscribe
              </button>
            </div>
          </div>

          <div className="free-trial-info">
            <h2>Free Trial Information</h2>
            <p>
              At FS Insight, we offer a free additional 30 days to those purchasing a monthly membership 
              for the first time. This free trial applies to new users only and is limited to one trial 
              per household. After your first month, you'll receive an extra 30 days of access at no 
              additional cost.
            </p>
          </div>

          <div className="upgrade-info">
            <h2>Why Upgrade to Premium?</h2>
            <div className="feature-list">
              <div className="feature-item">
                <div className="feature-header">Full Stock Pool</div>
                <div className="feature-content">Access exclusive Bitcoin-related stock data and metrics.</div>
              </div>
              <div className="feature-item">
                <div className="feature-header">Advanced Insights</div>
                <div className="feature-content">Get expert recommendations and comprehensive analytics.</div>
              </div>
              <div className="feature-item">
                <div className="feature-header">Custom Tools</div>
                <div className="feature-content">Unlock top picks and advanced stock screeners.</div>
              </div>
            </div>
            <div className="coming-soon">More features coming soon!</div>
          </div>

          {error && (
            <div className="error-message" style={{ marginTop: '1rem' }}>
              {error}
            </div>
          )}
        </div>

        <div className="footer-copyright">
          © 2024 ChainSec Limited. All Rights Reserved.
        </div>
      </div>
    </PageLayout>
  );
};

export default PricingPage;
