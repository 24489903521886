import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import './index.css';
import App from './App';
import './i18n';

console.log('Starting app initialization...');

const renderApp = () => {
  try {
    console.log('Creating root element...');
    const root = ReactDOM.createRoot(document.getElementById('root'));
    
    console.log('Rendering app...');
    root.render(
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    );
    console.log('App rendered successfully');
  } catch (error) {
    console.error('Failed to render app:', error);
    // 显示更详细的错误信息
    document.body.innerHTML = `
      <div style="text-align: center; padding: 20px;">
        <h1>Application Error</h1>
        <p>Error details: ${error.message}</p>
        <button onclick="window.location.reload()">Refresh Page</button>
      </div>
    `;
  }
};

// 确保 DOM 完全加载
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderApp);
} else {
  renderApp();
}

// 添加全局错误处理
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Global error:', { message, source, lineno, colno, error });
  return false;
};

window.addEventListener('unhandledrejection', function(event) {
  console.error('Unhandled promise rejection:', event.reason);
});