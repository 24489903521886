import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import { FaSearch } from 'react-icons/fa';
import './FAQPage.css';

const FAQPage = () => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState('product');
  const [expandedId, setExpandedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const categories = [
    {
      id: 'product',
      title: 'Product & Features',
    },
    {
      id: 'account',
      title: 'Account & Subscription',
    },
    {
      id: 'data',
      title: 'Data & Privacy',
    },
    {
      id: 'support',
      title: 'Technical Support',
    },
  ];

  const questions = [
    {
      id: 1,
      question: "What is FinStock Insights, and how does it work?",
      answer: `<p><span class="highlight">FinStock Insights</span> is a cutting-edge investment platform designed to simplify complex financial data and provide actionable insights for investors of all levels. Using advanced technology like <span class="highlight">AI</span>, our platform transforms overwhelming financial information into clear, concise metrics.</p>

<p>Whether you're analyzing <span class="highlight">Bitcoin-related stocks</span>, <span class="highlight">ETFs</span>, or the broader US stock market, <span class="highlight">FinStock Insights</span> empowers you to make smarter decisions with confidence.</p>

<p>Learn more about our platform on the <a href="/products" class="link">Product Overview Page</a>.</p>`
    },
    {
      id: 2,
      question: "What features are available in the free version?",
      answer: `<p><span class="highlight">FinStock Insights</span> offers free users access to market-wide analysis, such as:</p>

<ul>
  <li><span class="highlight">Bitcoin and cryptocurrency trends</span>: Understand the overall market dynamics.</li>
  <li><span class="highlight">US stock market insights</span>: Explore comprehensive trends and macro-level analysis.</li>
</ul>

<p>However, detailed insights into individual assets and specific recommendations require a premium membership. We are continuously developing tools for various asset classes, including:</p>
<ul>
  <li class="highlight-item">Bitcoin-related stocks</li>
  <li class="highlight-item">Bitcoin-related ETFs</li>
  <li class="highlight-item">Long-term investment stocks</li>
  <li class="highlight-item">Short-term investment stocks</li>
  <li class="highlight-item">Cryptocurrencies</li>
</ul>

<p>Free users can explore general market trends, but to unlock asset-specific insights and recommendations, consider upgrading to premium. Discover more on our <a href="/pricing" class="link">Pricing Page</a>.</p>`
    },
    {
      id: 3,
      question: "How Can Premium Features Maximize Your Returns and Elevate Your Investment Strategy?",
      answer: `<p>Upgrading to our premium membership unlocks a powerful suite of professional-grade tools and insights designed to help you achieve higher returns while managing risk effectively. Here's what you'll gain access to:</p>

<ul>
  <li><span class="highlight">In-depth analysis of individual stocks and cryptocurrencies</span>: Dive into comprehensive insights on Bitcoin-related stocks, ETFs, and cryptocurrencies to uncover new opportunities.</li>
  
  <li><span class="highlight">Expert recommendations</span>: Receive tailored investment strategies for both long-term growth and short-term gains.</li>
  
  <li><span class="highlight">Personalized investment tools</span>:
    <ul>
      <li class="highlight-item">Top Picks: Access a carefully curated portfolio crafted by a quantitative model to deliver superior returns relative to risk.</li>
      <li class="highlight-item">Stock Pool Filter: Quickly identify stocks that align with your specific investment criteria.</li>
      <li class="highlight-item">Risk Assessment Models: Optimize your portfolio with advanced risk analysis tools used by professionals.</li>
    </ul>
  </li>
</ul>

<p>Premium members enjoy exclusive access to actionable data, expert recommendations, and cutting-edge tools to make confident, informed investment decisions. Unlock your potential today by exploring our premium features on the <a href="/pricing" class="link">Upgrade Page</a>.</p>`
    }
  ];

  const accountQuestions = [
    {
      id: 'a1',
      question: "What payment methods are available?",
      answer: `<p>We use <span class="highlight">Stripe</span>, a secure and globally trusted payment platform, to process all transactions. Currently, we accept <span class="highlight">major credit/debit cards</span>, including Visa, Mastercard, and American Express, through Stripe.</p>

<p>Stripe ensures that your payments are processed securely, with advanced encryption and fraud detection measures in place to protect your financial information.</p>

<p>We are actively working on adding additional payment methods, such as <span class="highlight">PayPal</span> and <span class="highlight">other regional payment options</span>, to provide greater flexibility. Stay tuned for updates as we expand our payment options!</p>`
    },
    {
      id: 'a2',
      question: "How can I renew my subscription?",
      answer: `<p>Your subscription does not renew automatically. When your current plan expires, you will need to manually renew it to maintain access to our premium features.</p>

<p>To renew, simply log into your account, navigate to the <span class="highlight">Billing</span> section, and follow the instructions to complete the payment. You'll receive a reminder email before your subscription expires.</p>

<p>If you encounter any issues during the renewal process, please contact our <span class="highlight">Support Team</span> for assistance.</p>`
    },
    {
      id: 'a3',
      question: "Can I cancel my subscription at any time?",
      answer: `<p>Yes, you can cancel your subscription anytime through your <a href="/profile" class="link">Account Settings</a>. Once canceled, you will retain access to premium features until the end of your current billing cycle, after which your account will automatically revert to the free plan.</p>`
    },
    {
      id: 'a4',
      question: "Is there a free trial, and can I request a refund?",
      answer: `<p>We sincerely apologize, but due to the nature of our product, we currently do not offer free trials or refunds.</p>

<p>However, we are actively working on a <span class="highlight">trial feature</span> that will allow users to explore a limited portion of our premium content before committing to a subscription. Stay tuned for updates by following our platform closely!</p>`
    }
  ];

  const dataQuestions = [
    {
      id: 'd1',
      question: "How often is the stock data updated?",
      answer: `<p>The frequency of updates depends on the type of data:</p>
<ul>
  <li><span class="highlight">Price data:</span> Updated in real-time to reflect the most current market conditions.</li>
  <li><span class="highlight">Other data</span> (e.g., financial metrics, market indicators, etc.): Updated regularly to align with the latest publicly available information.</li>
</ul>
<p>This approach ensures that you always have access to accurate and relevant data for your investment decisions.</p>`
    },
    {
      id: 'd2',
      question: "Is my personal and financial information secure?",
      answer: `<p>Yes, we take the security of your personal and financial information very seriously. Our platform uses advanced encryption protocols and adheres to industry best practices to safeguard your data.</p>

<p>Additionally, all payments are securely processed through <span class="highlight">Stripe</span>, a globally trusted payment gateway known for its robust security measures. For more details, you can review our <a href="/about#privacy-section" class="link">Privacy Policy</a>.</p>`
    },
    {
      id: 'd3',
      question: "Where does your data come from?",
      answer: `<p>Our data is sourced from reputable and trusted providers, including:</p>
<ul>
  <li><span class="highlight">Official stock exchanges</span> for real-time price data.</li>
  <li><span class="highlight">Leading financial databases</span> for market trends, stock metrics, and historical data.</li>
</ul>
<p>This ensures that the information we provide is accurate, reliable, and backed by authoritative sources.</p>`
    },
    {
      id: 'd4',
      question: "What happens if my payment fails?",
      answer: `<p>If your payment fails, we will notify you immediately via email. Common reasons for payment failure include:</p>
<ul>
  <li><span class="highlight">Insufficient funds</span> in your account.</li>
  <li><span class="highlight">Expired or invalid</span> payment method.</li>
</ul>
<p>To resolve the issue, simply update your payment details in your <a href="/profile" class="link">Account Settings</a>. If further assistance is needed, our <span class="highlight">Support Team</span> is always available to help.</p>`
    }
  ];

  const supportQuestions = [
    {
      id: 's1',
      question: "Can I use the service on multiple devices?",
      answer: `<p>Yes, you can access your account on any device with a web browser, including desktops, laptops, tablets, and smartphones. Our platform is <span class="highlight">fully optimized for web use</span>, ensuring a seamless experience across all screen sizes.</p>

<p>While we currently do not have dedicated mobile apps for iOS or Android, we are actively exploring <span class="highlight">mobile app development</span> to enhance accessibility. Stay tuned for future updates!</p>`
    },
    {
      id: 's2',
      question: "What should I do if I forget my password?",
      answer: `<p>If you forget your password, simply click the <span class="highlight">"Forgot Password"</span> link on the login page. You will receive an email with instructions to reset your password.</p>

<p>For additional assistance, feel free to contact our <span class="highlight">Support Team</span>.</p>`
    },
    {
      id: 's3',
      question: "The tool isn't working properly. What should I do?",
      answer: `<p>If the platform isn't functioning as expected, try the following steps:</p>
<ul>
  <li><span class="highlight">Refresh the page</span> or clear your browser cache.</li>
  <li>Switch to a different web browser (we recommend <span class="highlight">Chrome, Firefox, or Safari</span>).</li>
  <li>Ensure you have a <span class="highlight">stable internet connection</span>.</li>
</ul>
<p>If the issue persists, please reach out to our <span class="highlight">Support Team</span> for further assistance.</p>`
    },
    {
      id: 's4',
      question: "How can I report a bug or issue?",
      answer: `<p>To report a bug or issue, please contact our <span class="highlight">Support Team</span> directly. Provide as much detail as possible, including:</p>
<ul>
  <li>A description of the issue.</li>
  <li>Steps to reproduce the problem.</li>
  <li>Screenshots (if applicable).</li>
</ul>
<p>Our team will address the issue promptly to ensure a smooth experience for all users. Reach out to us at <a href="/support" class="link">Support Contact</a>.</p>`
    }
  ];

  const toggleQuestion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const getCurrentQuestions = () => {
    switch(activeCategory) {
      case 'product':
        return questions;
      case 'account':
        return accountQuestions;
      case 'data':
        return dataQuestions;
      case 'support':
        return supportQuestions;
      default:
        return questions;
    }
  };

  const getFilteredQuestions = () => {
    if (!searchQuery.trim()) {
      return getCurrentQuestions();
    }

    const query = searchQuery.toLowerCase();
    const allQuestions = [
      ...questions,
      ...accountQuestions,
      ...dataQuestions,
      ...supportQuestions
    ];
    
    return allQuestions.filter(item => {
      const questionMatch = item.question.toLowerCase().includes(query);
      const answerMatch = stripHtml(item.answer).toLowerCase().includes(query);
      return questionMatch || answerMatch;
    });
  };

  const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    if (expandedId) {
      setExpandedId(null);
    }
    if (event.target.value) {
      setActiveCategory(null);
    } else {
      setActiveCategory('product');
    }
  };

  return (
    <PageLayout>
      <div className="faq-page">
        <div className="faq-content-wrapper">
          <div className="faq-search-container global">
            <div className="faq-search-wrapper">
              <FaSearch className="faq-search-icon" />
              <input
                type="text"
                placeholder="Search all FAQ sections..."
                className="faq-search-input"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>

          <div className="faq-layout">
            {/* 左侧分类导航 */}
            <div className="faq-categories">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className={`faq-category-item ${activeCategory === category.id ? 'active' : ''}`}
                  onClick={() => {
                    setActiveCategory(category.id);
                    setSearchQuery('');
                  }}
                >
                  {category.title}
                </div>
              ))}
            </div>

            {/* 右侧内容区 */}
            <div className="faq-main-content">
              <div className="faq-questions-list">
                {getFilteredQuestions().map((item) => (
                  <div 
                    key={item.id} 
                    className={`faq-question-item ${expandedId === item.id ? 'expanded' : ''}`}
                    onClick={() => toggleQuestion(item.id)}
                  >
                    <div className="faq-question-header">
                      <h3>{item.question}</h3>
                      <div className="faq-arrow-icon">{expandedId === item.id ? '−' : '+'}</div>
                    </div>
                    {expandedId === item.id && (
                      <div className="faq-answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
                    )}
                  </div>
                ))}
                {searchQuery && getFilteredQuestions().length === 0 && (
                  <div className="faq-no-results">
                    No results found for "{searchQuery}"
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FAQPage;
