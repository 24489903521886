import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);

  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription.data) return false;
      
      return (
        subscription.data.subscription_status === 'active' && 
        new Date(subscription.data.current_period_end) > new Date()
      );
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isSubscriptionValid()) {
    return <Navigate to="/pricing" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute; 