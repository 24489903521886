import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainPage from './components/MainPage';
import AboutPage from './components/AboutPage';
import FAQPage from './components/FAQPage';
import PricingPage from './components/PricingPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import UserProfilePage from './components/UserProfilePage';
import AppHeader from './components/AppHeader';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailurePage from './components/PaymentFailurePage';
import ProductsPage from './components/ProductsPage';
import Overview from './components/Overview';
import LongTermOverview from './components/LongTermOverview';
import MarketCap from './components/MarketCap';
import EtfNetInflow from './components/EtfNetInflow';
import FearGreedIndex from './components/FearGreedIndex';
import MvrvZ from './components/MvrvZ';
import { StockComponents, StockScreener } from './components/btc-stocks';
import { callLoginApi } from './login_api';
import { getSubscriptionInfo } from './services/stripe_api';
import { setupAuthRefresh, setupSubscriptionSync } from './services/auth_service';
import { store } from './store/index';
import StockDetail from './components/btc-stocks/StockDetail';
import ProtectedRoute from './components/ProtectedRoute';

// 添加错误边界组件
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('App Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Something went wrong</h1>
          <button onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  const [error, setError] = useState(null);
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const userData = useSelector(state => state.auth?.userData || null);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeAuth = async () => {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        try {
          // 验证并刷新会话
          const userData = await callLoginApi('/load-user');
          const subscriptionData = await getSubscriptionInfo(userData.userid);
          
          dispatch({ 
            type: 'SET_USER_DATA', 
            payload: {
              ...userData,
              subscription: subscriptionData
            }
          });
          
          // 设置自动刷新
          setupAuthRefresh(store);
          setupSubscriptionSync(store);
        } catch (error) {
          dispatch({ type: 'SET_LOGGED_OUT' });
        }
      }
    };

    initializeAuth();
  }, []);

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Error Loading Application</h1>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="app-container">
        <AppHeader />
        <main>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route 
              path="/login" 
              element={
                isLoggedIn ? (
                  <Navigate to="/profile" replace />
                ) : (
                  <LoginPage />
                )
              } 
            />
            <Route 
              path="/register" 
              element={
                isLoggedIn ? (
                  <Navigate to="/profile" replace />
                ) : (
                  <RegisterPage />
                )
              } 
            />
            <Route 
              path="/profile" 
              element={
                isLoggedIn ? (
                  <UserProfilePage />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="/payment/success" element={<PaymentSuccessPage />} />
            <Route path="/payment/failure" element={<PaymentFailurePage />} />
            <Route path="/products" element={<ProductsPage />}>
              <Route path="btc">
                <Route index element={<Overview defaultView={true} />} />
                <Route path="btc-overview" element={<Overview />} />
                <Route path="market-cap" element={<MarketCap />} />
                <Route path="etf-net-inflow" element={<EtfNetInflow />} />
                <Route path="fear-greed-index" element={<FearGreedIndex />} />
                <Route path="mvrv-z" element={<MvrvZ />} />
                <Route path="long-term-overview" element={<LongTermOverview />} />
                <Route path="stock-screener" element={
                  <ProtectedRoute>
                    <StockScreener />
                  </ProtectedRoute>
                } />
              </Route>
              <Route path="stocks">
                <Route path="small-cap/:ticker" element={
                  <ProtectedRoute>
                    <StockDetail />
                  </ProtectedRoute>
                } />
                <Route path="medium-cap/:ticker" element={
                  <ProtectedRoute>
                    <StockDetail />
                  </ProtectedRoute>
                } />
                <Route path="large-cap/:ticker" element={
                  <ProtectedRoute>
                    <StockDetail />
                  </ProtectedRoute>
                } />
              </Route>
            </Route>
          </Routes>
        </main>
      </div>
    </ErrorBoundary>
  );
}

export default App;