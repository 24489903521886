import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { callLoginApi } from '../login_api';
import './AppHeader.css';

const AppHeader = () => {
  const { t, i18n } = useTranslation();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.auth.userData);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: 'SET_LOGGED_OUT' });
    dispatch({ type: 'CLEAR_USER_DATA' });
    localStorage.removeItem('isLoggedIn');
  };

  const changeLanguage = async (lng) => {
    try {
      await i18n.changeLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
      console.log('Language changed to:', lng);
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="left-section">
          <Link to="/" className="logo">{t('websiteName')}</Link>
          <nav>
            <ul className="nav-links">
              <li><Link to="/products">{t('products')}</Link></li>
              <li><Link to="/about">{t('about')}</Link></li>
              <li><Link to="/pricing">{t('pricing')}</Link></li>
              <li><Link to="/faq">{t('faq')}</Link></li>
            </ul>
          </nav>
        </div>
        <div className="right-section">
          {/* 暂时注释掉语言选择器，但保留代码
          <div className="language-selector">
            <select 
              onChange={(e) => changeLanguage(e.target.value)} 
              value={i18n.language}
              className="language-select"
            >
              <option value="en">English</option>
              <option value="zh">中文</option>
            </select>
          </div>
          */}
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="user-profile-link">
                {userData?.firstName || t('profile')}
              </Link>
              <button onClick={handleLogout} className="logout-btn">{t('logOut')}</button>
            </>
          ) : (
            <Link to="/login" className="login-btn">{t('logIn')}</Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
