import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import './BciMetrics.css';
import BciPriceRatioChart from './BciPriceRatioChart';
import BciBalanceChart from './BciBalanceChart';

const MetricCard = ({ label, value, tooltip }) => (
  <div className="stock-bci-metric-card">
    <div className="stock-bci-metric-header">
      {tooltip ? (
        <Tooltip content={tooltip}>
          <span className="stock-bci-metric-label">{label}</span>
        </Tooltip>
      ) : (
        <span className="stock-bci-metric-label">{label}</span>
      )}
    </div>
    <div className="stock-bci-metric-value">{value}</div>
  </div>
);

const BciMetrics = ({ bciData, priceRatioHistory, balanceHistory }) => {
  const { t, i18n } = useTranslation();
  const category = i18n.language === 'zh' ? bciData?.category_cn : bciData?.category_en;

  const formatValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    return Number(value).toFixed(6);
  };

  if (!bciData) return <div className="stock-bci-loading">{t('loading')}</div>;

  // 定义所有需要展示的指标项
  const metrics = [
    {
      key: 'BTC_Relationship_Score',
      label: t('metrics.btcRelationshipScore'),
      tooltip: t('metrics.tooltips.btcRelationshipScore')
    },
    {
      key: 'Last_Day_BTC_Price_Ratio',
      label: t('metrics.btcPriceRatio'),
      tooltip: t('metrics.tooltips.btcPriceRatio')
    },
    {
      key: 'BTC_per_Share',
      label: t('metrics.btcPerShare'),
      tooltip: t('metrics.tooltips.btcPerShare')
    },
    {
      key: 'Correlation',
      label: t('metrics.correlationWithBtc'),
      tooltip: t('metrics.tooltips.correlationWithBtc')
    },
    {
      key: 'Beta',
      label: t('metrics.betaWithBtc'),
      tooltip: t('metrics.tooltips.betaWithBtc')
    },
    {
      key: 'Alpha',
      label: t('metrics.alphaWithBtc'),
      tooltip: t('metrics.tooltips.alphaWithBtc')
    }
  ];

  return (
    <div className="stock-bci-container">
      <div className="stock-bci-header">
        <p className="stock-bci-category">
          {category || (i18n.language === 'zh' ? '其他' : 'Other')}
        </p>
      </div>

      <div className="stock-bci-metrics-grid">
        {metrics.map(metric => (
          <MetricCard
            key={metric.key}
            label={metric.label}
            value={formatValue(bciData[metric.key])}
            tooltip={metric.tooltip}
          />
        ))}
      </div>

      <div className="stock-bci-charts-container">
        <div className="stock-bci-chart">
          <BciPriceRatioChart 
            data={priceRatioHistory?.data || []} 
          />
        </div>
        
        <div className="stock-bci-chart">
          <BciBalanceChart 
            data={balanceHistory?.data || []} 
          />
        </div>
      </div>
    </div>
  );
};

export default BciMetrics; 