import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { callLoginApi } from '../login_api';
import './RegisterPage.css';

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: ''
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { email, password, confirmPassword, firstName, lastName } = formData;

    // 验证密码匹配
    if (password !== confirmPassword) {
      setMessage(t('passwordsDoNotMatch'));
      setIsLoading(false);
      return;
    }

    // 验证所有必填字段
    if (!email || !password || !firstName || !lastName) {
      setMessage(t('allFieldsRequired'));
      setIsLoading(false);
      return;
    }

    // 调用注册 API
    callLoginApi('/register', {
      username: email,
      password,
      firstName,
      lastName
    })
      .then(data => {
        setMessage(t('registrationSuccessful'));
        setTimeout(() => navigate('/login'), 2000);
      })
      .catch(error => {
        setMessage(t('registrationFailed') + ': ' + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleSubmit}>
        <h2>{t('register')}</h2>
        {message && (
          <div className={`message ${message === t('registrationSuccessful') ? 'success' : 'error'}`}>
            {message}
          </div>
        )}
        
        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="username"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">{t('password')}</label>
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            autoComplete="new-password"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
          <input
            type="password"
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            autoComplete="new-password"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="firstName">{t('firstName')}</label>
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            autoComplete="given-name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">{t('lastName')}</label>
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            autoComplete="family-name"
            required
          />
        </div>

        <button 
          type="submit" 
          className={`register-button ${isLoading ? 'loading' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="button-content">
              <div className="spinner"></div>
              <span>{t('loading')}</span>
            </div>
          ) : (
            t('register')
          )}
        </button>
      </form>
      
      <p className="toggle-text">
        {t('alreadyHaveAccount')} <Link to="/login">{t('logIn')}</Link>
      </p>
    </div>
  );
};

export default RegisterPage;
