import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './ProductsPage.css';
import FearGreedMeter from './FearGreedMeter';
import { getBtcOverview, getStockOverview, getStockScreenerSingle, getTopPicks, getFirstTopPick, getOtherTopPicks, getStockPriceReturn } from '../services/data_api';
import Tooltip from './Tooltip';
import QASection from './QASection';
import Overview from './Overview';
import LongTermOverview from './LongTermOverview';
import MarketCap from './MarketCap';
import EtfNetInflow from './EtfNetInflow';
import FearGreedIndex from './FearGreedIndex';
import MvrvZ from './MvrvZ';
import { StockComponents, StockScreener } from './btc-stocks';
import { STOCK_LIST } from '../config/stocks';
import { useParams, Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { MARKET_CAP_CATEGORIES } from '../config/marketCategories';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import { 
  getBtcPriceReturn, 
  getTopAssetsDetails, 
  getTechnicalSeasonality 
} from '../services/data_api';
import SectionTitleBar from './common/SectionTitleBar';

const InfoCard = ({ imageUrl, title, bulletPoints, onClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className="info-card" onClick={onClick}>
      <div className="card-image-container">
        <img src={imageUrl} alt="" className="card-image" />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index} className="bullet-point">{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, type }) => {
  if (!isOpen) return null;

  const renderContent = () => {
    switch (type) {
      case 'btc':
        return (
          <iframe 
            className="modal-iframe"
            src="/Education Article/Education Article/education article.html"
            title="Why BTC"
            allowFullScreen
          />
        );
      case 'faq':
        return (
          <div className="modal-qa">
            <QASection />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {renderContent()}
      </div>
    </div>
  );
};

const ProductsPage = ({ defaultContent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  
  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription.data) return false;
      
      return (
        subscription.data.subscription_status === 'active' && 
        new Date(subscription.data.current_period_end) > new Date()
      );
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  const checkAccess = (pageType) => {
    if (pageType === 'pro') {
      if (!isLoggedIn) {
        navigate('/login', { state: { from: location } });
        return false;
      }
      if (!isSubscriptionValid()) {
        navigate('/pricing', { state: { from: location } });
        return false;
      }
    }
    return true;
  };

  const handleNavigation = (pageId) => {
    if (pageId === 'seasonality') {
      navigate('/products/btc/btc-overview#seasonality');
      setActiveCategory('btc-overview');
      return;
    }
    const pageType = getPageType(pageId);
    if (!checkAccess(pageType)) return;
    
    setActiveCategory(pageId);
    switch (pageId) {
      case 'overview':
        navigate('/products/btc');
        break;
      case 'btc-overview':
        navigate('/products/btc/btc-overview');
        break;
      case 'market-cap':
        navigate('/products/btc/market-cap');
        break;
      case 'etf-net-inflow':
        navigate('/products/btc/etf-net-inflow');
        break;
      case 'fear-greed-index':
        navigate('/products/btc/fear-greed-index');
        break;
      case 'mvrv-z':
        navigate('/products/btc/mvrv-z');
        break;
      case 'long-term-overview':
        navigate('/products/btc/long-term-overview');
        break;
      case 'stock-screener':
        navigate('/products/btc/stock-screener');
        break;
      default:
        navigate('/products/btc');
        break;
    }
  };

  const handleStockClick = (stock, category) => {
    if (!checkAccess('pro')) return;  // 所有股票都是 PRO 功能
    
    setActiveCategory(stock.toLowerCase());
    navigate(`/products/stocks/${category}/${stock}`);
  };

  const { t } = useTranslation();
  const { ticker } = useParams();
  const [activeCategory, setActiveCategory] = useState(ticker?.toLowerCase() || 'overview');
  const [expandedItems, setExpandedItems] = useState(['btc', 'btc-related-stocks']);
  const [btcData, setBtcData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [previewData, setPreviewData] = useState([]);

  useEffect(() => {
    const fetchBtcData = async () => {
      try {
        const [priceResponse, assetsResponse, technicalResponse] = await Promise.all([
          getBtcPriceReturn(),
          getTopAssetsDetails(),
          getTechnicalSeasonality()
        ]);

        const bitcoinAsset = assetsResponse.data.find(asset => asset.name === 'Bitcoin');
        const technicalData = technicalResponse.data[0];
        
        const combinedData = {
          price: priceResponse.data[0].current_price.toFixed(3),
          daily_return: priceResponse.data[0].return_to_previous.toFixed(2) + '%',
          market_cap_T: (bitcoinAsset.market_cap_value / 1000).toFixed(2) + 'T',
          mvrv_z_score: technicalData.MVRV_Z_Score,
          current_month: technicalData.Month,
          monthly_mean: (technicalData.Month_Return_Mean * 100).toFixed(2) + '%',
          fear_greed_index: technicalData.Greed_Fear_Index,
          total_score: technicalData.Total_Score
        };

        setBtcData(combinedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching BTC data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBtcData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (isSubscriptionValid()) {
          const response = await getTopPicks();
          const priceReturnPromises = response.data.map(stock => 
            getStockPriceReturn(stock.ticker)
              .then(priceData => ({
                ...stock,
                price_return: priceData
              }))
              .catch(() => stock)
          );
          const enrichedData = await Promise.all(priceReturnPromises);
          setPreviewData(enrichedData);
        } else {
          const [firstPick, otherPicks] = await Promise.all([
            getFirstTopPick(),
            getOtherTopPicks()
          ]);
          
          const firstPickWithPrice = await getStockPriceReturn(firstPick.ticker)
            .then(priceData => ({
              ...firstPick,
              price_return: priceData
            }))
            .catch(() => firstPick);

          const combinedData = [
            firstPickWithPrice,
            ...otherPicks.data.map(item => ({
              ...getBlurredData(item),
              price_return: {
                current_price: 100.00,
                current_return: 1.23
              }
            }))
          ];
          setPreviewData(combinedData);
        }
      } catch (error) {
        console.error('Error fetching preview data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isSubscriptionValid()]);

  const navigationItems = [
    { 
      id: 'overview',
      label: 'BTC Dashboard',
      type: 'page'
    },
    {
      id: 'btc',
      label: 'BTC Briefing',
      type: 'group',
      children: [
        { id: 'btc-overview', label: 'Overview' },
        { id: 'market-cap', label: 'Market Cap' },
        { id: 'etf-net-inflow', label: 'ETF Net Inflow' }
      ]
    },
    {
      id: 'btc-related-stocks',
      label: 'BTC-related stocks',
      type: 'group',
      children: [
        { id: 'stock-screener', label: 'Stock Screener' },
        { 
          id: 'small-cap',
          label: 'Small Market Cap',
          type: 'expandable',
          stocks: [
            'BYON', 'SMLR', 'FRMO', 'CAN', 'HIVE', 'EXOD', 'BTBT', 'BITF', 'BTDR',
            'HUT', 'IREN', 'CIFR', 'WULF', 'CLSK', 'CORZ', 'RIOT', 'MARA'
          ]
        },
        { 
          id: 'medium-cap',
          label: 'Medium Market Cap',
          type: 'expandable',
          stocks: [
            'GLOB', 'SOFI', 'RDDT', 'HOOD', 'SQ', 'MSTR', 'COIN', 'CME', 'PYPL'
          ]
        },
        { 
          id: 'large-cap',
          label: 'Large Market Cap',
          type: 'expandable',
          stocks: [
            'SHOP', 'MELI', 'BLK', 'AMD', 'MA', 'V', 'TSLA', 'META', 'NVDA'
          ]
        }
      ]
    }
  ];

  const toggleExpand = (itemId) => {
    if (['small-cap', 'medium-cap', 'large-cap'].includes(itemId)) {
      if (!isSubscriptionValid()) {
        navigate('/pricing', { 
          state: { 
            from: location.pathname,
            redirectAfterPurchase: true 
          } 
        });
        return;
      }
    }
    
    setExpandedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  const getReturnColor = (returnStr) => {
    const value = parseFloat(returnStr.replace(/[^-\d.]/g, ''));
    
    if (value >= 3) return '#1B5E20';      // 深绿色
    if (value >= 1) return '#2E7D32';      // 浅绿色
    if (value > -1) return '#F57F17';      // 深黄色
    if (value > -3) return '#D32F2F';      // 浅色
    return '#B71C1C';                      // 深色
  };

  const getMVRVColor = (value) => {
    if (value <= 3) return '#2E7D32';      // 绿色
    if (value <= 6) return '#F57F17';      // 深黄色
    return '#D32F2F';                      // 红色
  };

  const getMarketAssessment = (totalScore) => {
    if (totalScore > 3.5) {
      return 'marketAssessment.highPotential';
    } else if (totalScore > 2) {
      return 'marketAssessment.moderate';
    } else {
      return 'marketAssessment.highRisk';
    }
  };

  // 修改 getDescription 函数
  const getDescription = (type, grade) => {
    const descriptions = {
      bci: {
        'A': 'Very Strong',
        'B': 'Strong',
        'C': 'Medium',
        'D': 'Weak',
        'E': 'Very Weak'
      },
      vp: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      risk: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      sentiment: {
        'A': 'Very Bullish',
        'B': 'Bullish',
        'C': 'Neutral',
        'D': 'Bearish',
        'E': 'Very Bearish'
      },
      recommendation: {  // 添加 recommendation 映射
        'A': 'Strong Buy',
        'B': 'Buy',
        'C': 'Hold',
        'D': 'Sell',
        'E': 'Strong Sell'
      }
    };

    return descriptions[type]?.[grade] || grade;
  };

  // 补充完整的 renderGrade 函数
  const renderGrade = (grade, type) => {
    if (!grade) return '-';
    
    const getGradeColor = (grade, type) => {
      if (type === 'risk' || type === 'vp') {
        const riskColors = {
          'A': '#1B5E20', // very low - 深绿色
          'B': '#388E3C', // low - 绿色
          'C': '#FDD835', // medium - 黄色
          'D': '#F57C00', // high - 橙色
          'E': '#D32F2F'  // very high - 深红色
        };
        return riskColors[grade] || '#ddd';
      }
      
      const colors = {
        'A': '#388E3C',
        'B': '#388E3C',
        'C': '#FDD835', 
        'D': '#F57C00',
        'E': '#D32F2F'
      };
      return colors[grade] || '#ddd';
    };

    return (
      <div className="preview-grade-container">
        {['bci', 'sentiment', 'recommendation'].includes(type) && (
          <>
            <div className="preview-horizontal-indicator">
              <div className="preview-marker" style={{
                left: grade === 'A' ? '100%' : 
                      grade === 'B' ? '75%' : 
                      grade === 'C' ? '50%' : 
                      grade === 'D' ? '25%' : '0%'
              }}></div>
            </div>
            <span className="preview-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
        {['vp', 'risk'].includes(type) && (
          <>
            <div className="preview-stacked-bars">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="preview-bar"
                  style={{
                    backgroundColor: i < (
                      grade === 'E' ? 5 :
                      grade === 'D' ? 4 :
                      grade === 'C' ? 3 :
                      grade === 'B' ? 2 : 1
                    ) ? getGradeColor(grade, type) : '#ddd'
                  }}
                />
              ))}
            </div>
            <span className="preview-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderOverviewContent = () => {
    // 只在加载时显示 Loading
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!previewData || !btcData) return null;  // 返回 null 而不是显示 "No data available"

    const getMonthName = (monthNumber) => {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];
      return months[monthNumber - 1];
    };

    return (
      <div className="overview-content">
        <div className="btc-header">
          <div className="btc-info">
            <div className="btc-title">
              <img src="/images/Bitcoinlogo.png" alt="BTC" className="btc-logo" />
              <h1>Bitcoin. (BTC)</h1>
            </div>
            <div className="price-info">
              <span className="label">{t('currentPrice')}:</span>
              <span className="value">${btcData.price}</span>
              <span 
                className="change"
                style={{ color: getReturnColor(btcData.daily_return) }}
              >
                ({btcData.daily_return})
              </span>
            </div>
            <div className="market-cap">
              <Tooltip content={t('tooltips.marketCap.btc')}>
                <div className="label-container">
                  <span className="label">Market Cap: </span>
                  <span className="value">${btcData.market_cap_T}</span>
                </div>
              </Tooltip>
            </div>
            <div className="mvrv-z">
              <Tooltip content={t('tooltips.mrvzScore')}>
                <div className="label-container">
                  <span className="label">MVRV-Z value: </span>
                  <span 
                    className="value" 
                    style={{ color: getMVRVColor(btcData.mvrv_z_score) }}
                  >
                    {btcData.mvrv_z_score}
                  </span>
                </div>
              </Tooltip>
            </div>
            <div 
              className="seasonality" 
              onClick={() => handleNavigation('seasonality')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('seasonality');
                }
              }}
            >
              <Tooltip content={t('tooltips.seasonality')}>
                <span className="label">Seasonality: {getMonthName(btcData.current_month)}</span>
              </Tooltip>
              <span className="value">
                <span className="value-tag" style={{ backgroundColor: '#388E3C', color: '#fff' }}>
                  {btcData.monthly_mean}
                </span>
              </span>
            </div>
            <div className="risk-assessment">
              <p>{t(getMarketAssessment(btcData.total_score))}</p>
            </div>
          </div>
          <div className="fear-greed-meter-container">
            <FearGreedMeter value={btcData.fear_greed_index} />
          </div>
        </div>

        <div className="info-cards">
          <InfoCard
            imageUrl="/images/WhyBTC.jpg"
            title="How to Seize Bitcoin Market Opportunities through US Stocks?"
            bulletPoints={[
              'Why Invest in Bitcoin?',
              'Why US Stocks?',
              'Our Unique Advantages',
              'How to Use Our Paid Services?'
            ]}
            onClick={() => handleCardClick('btc')}
          />
          
          <div className="preview-table-container">
            <SectionTitleBar title="Top Picks" id="top-picks" />
            <p className="preview-table-description">
              Top Picks are a curated portfolio selected for strong performance potential and balanced risks, 
              tailored to current Bitcoin market conditions. Users can use these recommendations to build or 
              refine their portfolios, guided by quantitative modeling, diversification strategies, and 
              analysts' insights.
            </p>
            <div className="preview-scroll-container">
              <div className="preview-table-wrapper">
                <table className="preview-table">
                  <thead>
                    <tr>
                      {columns.map(column => (
                        <th 
                          key={column.key}
                          className={`preview-align-${column.align}`}
                        >
                          <div className="th-content">
                            {column.tooltip ? (
                              <Tooltip content={t(column.tooltip)}>
                                <span>{column.label}</span>
                              </Tooltip>
                            ) : (
                              <span>{column.label}</span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {previewData?.map((stock, index) => (
                      <tr key={stock.ticker || index}>
                        {columns.map(column => (
                          <td 
                            key={column.key}
                            className={`preview-align-${column.align}`}
                          >
                            {renderCell(stock[column.key], stock, column, index)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="explore-button-container">
              <button className="explore-button" onClick={handleShowMore}>
                <span className="explore-button-title">
                  Explore All Stocks
                </span>
                <span className="explore-button-description">
                  See the full list of BTC-related stocks and access advanced filtering options
                </span>
              </button>
            </div>
          </div>

          <InfoCard
            imageUrl="/images/QA.jpg"
            title={t('qa.title')}
            bulletPoints={[
              t('qa.questions.1.q'),
              t('qa.questions.2.q'),
              t('qa.questions.3.q'),
              '...'
            ]}
            onClick={() => handleCardClick('faq')}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // 如果数据正在加载，返回加载状态
    if (loading) {
      return <div className="loading-state">Loading...</div>;
    }

    // 果有错误，返回错误状态
    if (error) {
      return <div className="error-state">{error}</div>;
    }

    // 如果是主概览页面
    if (defaultContent === 'overview' || activeCategory === 'overview') {
      // 确保 btcData 存在再渲染
      return btcData ? renderOverviewContent() : null;
    }

    // 如果是 stock-screener 页面
    if (location.pathname === '/products/btc/stock-screener') {
      return <Outlet />;
    }

    // 如果是股票页面
    if (STOCK_LIST.includes(activeCategory.toUpperCase())) {
      const StockComponent = StockComponents[activeCategory.toUpperCase()];
      return <StockComponent />;
    }

    // 其他页面的渲染逻辑
    switch (activeCategory) {
      case 'stock-screener':
        return <StockScreener />;
        
      case 'btc-overview':
        return btcData ? <Overview /> : null;
        
      case 'market-cap':
        return <MarketCap />;
        
      case 'fear-greed-index':
        return <FearGreedIndex />;
        
      case 'mvrv-z':
        return <MvrvZ />;
        
      case 'etf-net-inflow':
        return <EtfNetInflow />;
        
      case 'long-term-overview':
        return <LongTermOverview />;
        
      default:
        return null;
    }
  };

  const handleCardClick = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  // 添加一辅助函数来断页面类型
  const getPageType = (pageId) => {
    const proPages = ['stock-screener', 'small-cap', 'medium-cap', 'large-cap'];
    const freePages = [
      'overview', 
      'btc-overview', 
      'market-cap', 
      'fear-greed-index', 
      'mvrv-z', 
      'etf-net-inflow', 
      'long-term-overview'
    ];
    
    if (proPages.includes(pageId)) return 'pro';
    if (freePages.includes(pageId)) return 'free';
    return null;
  };

  // 在组件加载和 URL 参数变化时更新状态
  useEffect(() => {
    if (ticker) {
      const category = findStockCategory(ticker);
      if (category) {
        setExpandedItems(prev => {
          const newItems = new Set([...prev, 'btc-related', category]);
          return Array.from(newItems);
        });
      }
      setActiveCategory(ticker.toLowerCase());
    }
  }, [ticker]);

  // 添加一个函来处理股票分类
  const findStockCategory = (ticker) => {
    if (!ticker) return null;
    const upperTicker = ticker.toUpperCase();
    if (MARKET_CAP_CATEGORIES.SMALL.stocks.includes(upperTicker)) {
      return 'small-cap';
    } else if (MARKET_CAP_CATEGORIES.MEDIUM.stocks.includes(upperTicker)) {
      return 'medium-cap';
    } else if (MARKET_CAP_CATEGORIES.LARGE.stocks.includes(upperTicker)) {
      return 'large-cap';
    }
    return null;
  };

  // 修改 handleShowMore 函数
  const handleShowMore = () => {
    try {
      // 检查是否已登录
      if (!isLoggedIn) {
        navigate('/login', { 
          state: { 
            from: location.pathname,
            redirectAfterLogin: true 
          } 
        });
        return;
      }

      // 检查是否有 PRO 权限
      if (!checkAccess('pro')) {
        setModalType('upgrade');
        setModalOpen(true);
        return;
      }

      // 检查订阅状态
      if (!isSubscriptionValid()) {
        navigate('/pricing', { 
          state: { 
            from: location.pathname,
            redirectAfterPurchase: true 
          } 
        });
        return;
      }

      // 使用与侧边栏相同的导航逻辑
      handleNavigation('stock-screener');
    } catch (error) {
      console.error('Error in handleShowMore:', error);
      // 可以添加错误提示 UI
    }
  };

  // 定义表格列配置
  const columns = [
    { 
      key: 'ticker',
      label: 'Ticker',
      align: 'left',
      render: (value) => (
        <span className="preview-stock-link">
          {value}
        </span>
      )
    },
    { 
      key: 'name', 
      label: 'Name', 
      align: 'left' 
    },
    {
      key: 'price_return',
      label: 'Current Price (Return)',
      align: 'right',
      render: (value, row) => {
        if (!value || row.isBlurred) {
          return (
            <div className="stock-detail-price-info blur-content">
              <span>${value?.current_price || '156.78'}</span>
              <span className="preview-positive">
                +2.45%
              </span>
            </div>
          );
        }
        const { current_price, current_return } = value;
        return (
          <div className="stock-detail-price-info">
            <span>${current_price.toFixed(2)}</span>
            <span className={`${current_return >= 0 ? 'preview-positive' : 'preview-negative'}`}>
              {`${current_return >= 0 ? '+' : ''}${current_return.toFixed(2)}%`}
            </span>
          </div>
        );
      }
    },
    { 
      key: 'recent_performance',
      label: 'Recent Performance',
      align: 'center',
      tooltip: 'tooltips.recentPerformance',
      render: (value, row) => {
        if (row.isBlurred) {
          return row.performance_range || '-';  // 显示区间数据
        }
        if (typeof value === 'number') {
          return (
            <span className={value >= 0 ? 'preview-positive' : 'preview-negative'}>
              {(value * 100).toFixed(2)}%
            </span>
          );
        }
        return '-';
      }
    },
    { 
      key: 'market_cap_category',
      label: 'Market Cap (Billion)',
      align: 'right',
      render: (value) => {
        const categoryMap = {
          'S': 'Small',
          'M': 'Medium',
          'L': 'Large'
        };
        return categoryMap[value] || '-';
      }
    },
    {
      key: 'bci',
      label: 'Correlation_BTC',
      align: 'center',
      tooltip: 'tooltips.bci',
      render: (value) => renderGrade(value, 'bci')
    },
    {
      key: 'vp',
      label: 'Premium',
      align: 'center',
      tooltip: 'tooltips.vp',
      render: (value) => renderGrade(value, 'vp')
    },
    {
      key: 'risk',
      label: 'Risk',
      align: 'center',
      tooltip: 'tooltips.risk',
      render: (value) => renderGrade(value, 'risk')
    },
    {
      key: 'sentiment_rank',
      label: 'Sentiment Rank',
      align: 'center',
      tooltip: 'tooltips.sentiment',
      render: (value) => renderGrade(value, 'sentiment')
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      align: 'center',
      tooltip: 'tooltips.recommendation',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  // ���例数据
  const mockData = [{
    ticker: "RIOT",
    name: "Riot Platforms, Inc.",
    sector: "Financial Services",
    previous_close: 14.32,  // 确保是数类型
    recent_performance: 0.7475862174198543,  // 确保是数字类型
    market_cap_category: "Medium",
    bci: "A",
    vp: "C",
    risk: "B",
    sentiment: "C"
  }];

  // 添加一个辅助函数来生成模拟的评级显示数据
  const getBlurredData = (item) => ({
    ticker: '****',
    name: '********',
    sector: '********',
    market_cap_category: 'M',
    recent_performance: 0.15,
    performance_range: item.performance_range,
    // 添加价格和回报率的模拟数据
    price_return: {
      current_price: 156.78,
      current_return: 2.45
    },
    bci: 'A',
    vp: 'B',
    risk: 'C',
    sentiment_rank: 'A',
    recommendation: 'B',
    sort_order: item.sort_order,
    isBlurred: true,
    bciText: 'Very Strong',
    vpText: 'High',
    riskText: 'Medium',
    sentimentText: 'Very Bullish',
    recommendationText: 'Buy'
  });

  // 修改表格单元格渲染逻辑
  const renderCell = (value, rowData, column) => {
    // 第一行永远不模糊
    if (rowData.sort_order === 1) {
      return column.render ? column.render(value, rowData) : (value || '-');
    }

    // 非付费用户时的��染逻辑
    if (!isSubscriptionValid()) {
      if (column.key === 'recent_performance') {
        // 使用区间数据并保持颜色样式
        const range = rowData.performance_range;
        const isPositive = !range.startsWith('-');
        return (
          <span className={isPositive ? 'preview-positive' : 'preview-negative'}>
            {range}
          </span>
        );
      }
      // 其他列显示模糊效果，但保持完整的渲染结构
      return (
        <div className="blur-content">
          {column.render ? column.render(value, rowData) : '****'}
        </div>
      );
    }

    return column.render ? column.render(value, rowData) : (value || '-');
  };

  return (
    <>
      <PageLayout>
        <div className="products-container">
          <aside className="products-sidebar">
            <nav className="sidebar-nav">
              {navigationItems.map(item => (
                <div key={item.id} className="nav-item-container">
                  <button
                    className={`sidebar-item ${activeCategory === item.id ? 'active' : ''} ${item.type === 'group' ? 'group' : ''}`}
                    onClick={() => item.type === 'group' ? toggleExpand(item.id) : setActiveCategory(item.id)}
                    aria-expanded={expandedItems.includes(item.id)}
                  >
                    <span className="item-content">
                      {item.label}
                    </span>
                    {(item.type === 'group' || item.type === 'expandable') && (
                      <span className={`expand-icon ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>▼</span>
                    )}
                  </button>
                  {item.type === 'group' && expandedItems.includes(item.id) && (
                    <div className="sub-items">
                      {item.children.map(child => {
                        if (child.type === 'expandable') {
                          return (
                            <div key={child.id} className="market-cap-category">
                              <button
                                className={`sidebar-item ${activeCategory === child.id ? 'active' : ''}`}
                                onClick={() => toggleExpand(child.id)}
                              >
                                <span className="item-content">{child.label}</span>
                                <span className="feature-tag pro-tag">PRO</span>
                                <span className={`expand-icon ${expandedItems.includes(child.id) ? 'expanded' : ''}`}>▼</span>
                              </button>
                              {expandedItems.includes(child.id) && isSubscriptionValid() && (
                                <div className="stock-list">
                                  {child.stocks.map(stock => (
                                    <button
                                      key={stock}
                                      className={`sidebar-item stock-item ${activeCategory === stock.toLowerCase() ? 'active' : ''} pro-feature`}
                                      onClick={() => handleStockClick(stock, child.id)}
                                    >
                                      <span className="item-content">{stock}</span>
                                      <span className="feature-tag pro-tag">PRO</span>
                                    </button>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <button
                              key={child.id}
                              className={`sidebar-item ${activeCategory === child.id ? 'active' : ''} ${getPageType(child.id) ? `${getPageType(child.id)}-feature` : ''}`}
                              onClick={() => handleNavigation(child.id)}
                            >
                              <span className="item-content">{child.label}</span>
                              {getPageType(child.id) === 'pro' && <span className="feature-tag pro-tag">PRO</span>}
                              {getPageType(child.id) === 'free' && <span className="feature-tag free-tag">FREE</span>}
                            </button>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </aside>
          <main className="products-main-content">
            {renderContent()}
          </main>
        </div>
      </PageLayout>
      <Modal 
        isOpen={modalOpen} 
        onClose={() => {
          setModalOpen(false);
          setModalType(null);
        }}
        type={modalType}
      />
    </>
  );
};

export default ProductsPage; 
 