import React, { useState, useEffect } from 'react';
import './StockDetail.css';
import { getStockDetailOverview, getStockPriceReturn, getStockBasicInfo, formatNumber, getStockRiskData, getStockHistory, getStockBciData, getStockPriceRatioHistory, getStockBalanceHistory, getStockCategory, getValuationPremium } from '../../services/data_api';
import { useTranslation } from 'react-i18next';
import RiskMetrics from './RiskMetrics';
import ReactECharts from 'echarts-for-react';
import BciMetrics from './BciMetrics';
import ValuationPremium from './ValuationPremium';
import { useParams } from 'react-router-dom';
import StockRecommendation from './StockRecommendation';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const tabs = [
  { key: 'overview', label: 'Overview' },
  { key: 'bci', label: 'Correlation_BTC' },
  { key: 'vp', label: 'Premium' },
  { key: 'risk', label: 'Risk' },
  { key: 'recommendation', label: 'Recommendation' }
];

const StockDetail = () => {
  const { ticker } = useParams();
  const [stockData, setStockData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [riskData, setRiskData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [bciData, setBciData] = useState(null);
  const [priceRatioHistory, setPriceRatioHistory] = useState(null);
  const [balanceHistory, setBalanceHistory] = useState(null);
  const [valuationPremium, setValuationPremium] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (!ticker) {
        setError('No ticker provided');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const [overviewResult, priceReturnResult, basicInfoResult] = await Promise.allSettled([
          getStockDetailOverview(ticker),
          getStockPriceReturn(ticker),
          getStockBasicInfo(ticker)
        ]);

        const newStockData = {};
        
        if (overviewResult.status === 'fulfilled') {
          const overview = overviewResult.value;
          Object.assign(newStockData, {
            ...overview,
            bci: overview.btc_correlation_index,
            vp: overview.valuation_premium,
            recent_performance: (overview.recent_performance * 100).toFixed(2),
            market_cap: overview.market_cap_billion * 1e9,
          });
        }
        
        if (basicInfoResult.status === 'fulfilled') {
          Object.assign(newStockData, basicInfoResult.value);
        }

        if (priceReturnResult.status === 'fulfilled') {
          setPriceData(priceReturnResult.value);
        }

        if (Object.keys(newStockData).length > 0) {
          setStockData(newStockData);
        }

      } catch (error) {
        console.error('Error fetching stock data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ticker]);

  useEffect(() => {
    const fetchRiskData = async () => {
      try {
        const data = await getStockRiskData(ticker);
        setRiskData(data);
      } catch (error) {
        console.error('Error fetching risk data:', error);
      }
    };

    if (ticker) {
      fetchRiskData();
    }
  }, [ticker]);

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await getStockHistory(ticker);
        setHistoryData(response.data);
      } catch (error) {
        console.error('Error fetching stock history:', error);
      }
    };
    fetchHistoryData();
  }, [ticker]);

  useEffect(() => {
    const fetchBciData = async () => {
      try {
        const [bciResponse, priceRatioResponse, balanceResponse, categoryResponse] = 
          await Promise.allSettled([
            getStockBciData(ticker),
            getStockPriceRatioHistory(ticker),
            getStockBalanceHistory(ticker),
            getStockCategory(ticker)
          ]);

        if (bciResponse.status === 'fulfilled') {
          const bciData = bciResponse.value;
          const categoryData = categoryResponse.status === 'fulfilled' ? categoryResponse.value : {};
          
          setBciData({
            ...bciData,
            category_en: categoryData.category_en || "Other",
            category_cn: categoryData.category_cn || "其他"
          });
        }

        if (priceRatioResponse.status === 'fulfilled') {
          setPriceRatioHistory(priceRatioResponse.value);
        }

        if (balanceResponse.status === 'fulfilled') {
          setBalanceHistory(balanceResponse.value);
        }

      } catch (error) {
        console.error('Error fetching BCI data:', error);
      }
    };

    if (ticker) {
      fetchBciData();
    }
  }, [ticker]);

  useEffect(() => {
    const fetchValuationPremium = async () => {
      try {
        const data = await getValuationPremium(ticker);
        setValuationPremium(data);
      } catch (error) {
        console.error('Error fetching valuation premium data:', error);
        setValuationPremium(null);
      }
    };

    fetchValuationPremium();
  }, [ticker]);

  const getHistoryChartOption = () => {
    if (!historyData || !Array.isArray(historyData)) {
      return {
        title: {
          text: 'No data available',
          textStyle: { color: '#666', fontSize: 14 },
          left: 'center',
          top: 'center'
        }
      };
    }

    const filterDataByRange = (data, range) => {
      if (range === 'ALL') return data;
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === range).days);
      return data.filter(item => new Date(item.date) >= cutoffDate);
    };

    const validData = filterDataByRange(
      historyData.filter(item => 
        item && 
        item.date && 
        typeof item.close === 'number' && 
        typeof item.volume === 'number'
      ),
      selectedRange
    );

    return {
      backgroundColor: '#ffffff',
      textStyle: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
        fontSize: 12
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#1a73e8'
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: '#eee',
        borderWidth: 1,
        textStyle: { color: '#333' },
        formatter: function(params) {
          const date = params[0].axisValue;
          const price = params[0].data.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
          const volume = params[1].data.toLocaleString('en-US');
          return `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
            <div style="color: #1a73e8;">Price: ${price}</div>
            <div style="color: #34a853;">Volume: ${volume}</div>
          </div>`;
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5,
          borderColor: '#ddd',
          fillerColor: 'rgba(26, 115, 232, 0.1)',
          handleStyle: { color: '#1a73e8' }
        }
      ],
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        top: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: validData.map(item => item.date),
        axisLine: { lineStyle: { color: '#ddd' } },
        axisLabel: {
          rotate: 45,
          color: '#666'
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Price (USD)',
          position: 'right',
          nameTextStyle: {
            color: '#1a73e8',
            fontSize: 20,
            padding: [0, 0, 10, 0],
            align: 'center'
          },
          axisLine: {
            show: true,
            lineStyle: { color: '#1a73e8' }
          },
          axisLabel: { color: '#666' },
          splitLine: {
            lineStyle: { color: '#f5f5f5' }
          }
        },
        {
          type: 'value',
          name: 'Volume',
          position: 'left',
          nameTextStyle: {
            color: '#34a853',
            fontSize: 20,
            padding: [0, 0, 10, 0],
            align: 'center'
          },
          axisLine: {
            show: true,
            lineStyle: { color: '#34a853' }
          },
          axisLabel: { color: '#666' },
          splitLine: { show: false }
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          data: validData.map(item => item.close),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#1a73e8'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0, y: 0, x2: 0, y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26, 115, 232, 0.2)'
              }, {
                offset: 1,
                color: 'rgba(26, 115, 232, 0)'
              }]
            }
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          yAxisIndex: 1,
          data: validData.map(item => item.volume),
          itemStyle: {
            color: '#34a853',
            opacity: 0.3
          }
        }
      ]
    };
  };

  const renderMetricCard = (title, value, type) => {
    if (!value) return null;
    
    const renderGrade = (grade, type) => {
      if (!grade) return '-';
      
      const getGradeColor = (grade, type) => {
        if (type === 'risk' || type === 'vp') {
          const riskColors = {
            'A': '#D32F2F',
            'B': '#F57C00',
            'C': '#FDD835',
            'D': '#388E3C',
            'E': '#1B5E20'
          };
          return riskColors[grade] || '#ddd';
        }
        
        const colors = {
          'A': '#388E3C',
          'B': '#388E3C',
          'C': '#FDD835',
          'D': '#F57C00',
          'E': '#D32F2F'
        };
        return colors[grade] || '#ddd';
      };

      const description = type === 'bci' ? (
        grade === 'A' ? 'very strong' :
        grade === 'B' ? 'strong' :
        grade === 'C' ? 'medium' :
        grade === 'D' ? 'weak' : 'very weak'
      ) : type === 'vp' ? (
        grade === 'A' ? 'very high' :
        grade === 'B' ? 'high' :
        grade === 'C' ? 'medium' :
        grade === 'D' ? 'low' : 'very low'
      ) : type === 'risk' ? (
        grade === 'A' ? 'very high' :
        grade === 'B' ? 'high' :
        grade === 'C' ? 'medium' :
        grade === 'D' ? 'low' : 'very low'
      ) : type === 'sentiment' ? (
        grade === 'A' ? 'very bullish' :
        grade === 'B' ? 'bullish' :
        grade === 'C' ? 'neutral' :
        grade === 'D' ? 'bearish' : 'very bearish'
      ) : '';

      return (
        <div className="stock-detail-grade-container">
          {['bci', 'sentiment'].includes(type) && (
            <div className="stock-detail-horizontal-indicator">
              <div className="stock-detail-marker" style={{
                left: grade === 'A' ? '100%' : 
                      grade === 'B' ? '75%' : 
                      grade === 'C' ? '50%' : 
                      grade === 'D' ? '25%' : '0%'
              }}></div>
            </div>
          )}
          {['vp', 'risk'].includes(type) && (
            <div className="stock-detail-stacked-bars">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="stock-detail-bar"
                  style={{
                    backgroundColor: i < (
                      grade === 'A' ? 5 :
                      grade === 'B' ? 4 :
                      grade === 'C' ? 3 :
                      grade === 'D' ? 2 : 1
                    ) ? getGradeColor(grade, type) : '#ddd'
                  }}
                />
              ))}
            </div>
          )}
          <span className="stock-detail-grade-description">{description}</span>
        </div>
      );
    };

    return (
      <div className="stock-detail-metric-card">
        <h3 className="stock-detail-metric-title">{title}</h3>
        {renderGrade(value, type)}
      </div>
    );
  };

  const renderBusinessActivities = () => {
    if (!ticker || !t(`stockActivities.${ticker}.points`)) {
      return null;
    }

    return (
      <div className="stock-detail-key-aspects">
        <h3 className="stock-detail-section-title">{t('stockActivities.title')}</h3>
        <ul>
          {t(`stockActivities.${ticker}.points`, { returnObjects: true }).map((point, index) => {
            const [title, content] = point.split(': ');
            return (
              <li key={index}>
                <strong>{title}</strong>: {content}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderPriceChart = () => {
    if (!historyData) return null;
    return (
      <div className="history-chart-section">
        <div className="chart-header">
          <h3>{t('Historical Price & Volume')}</h3>
          <div className="time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`range-button ${selectedRange === range.label ? 'active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getHistoryChartOption()} 
          style={{ height: '400px' }}
          notMerge={true}
        />
      </div>
    );
  };

  const renderRiskMetrics = () => {
    if (!riskData) return null;
    
    const {
      volatility,
      beta,
      volume,
      turnoverRatio,
      maxDrawdown,
      var5: valueAtRisk,
      sharpeRatio,
      sortinoRatio,
      debtToEquity,
      currentRatio,
      profitMargin,
      roa,
      interestCoverage,
      altmanZScore
    } = riskData;

    return (
      <div className="risk-metrics-container">
        <div className="risk-metric">
          <h4>Volatility</h4>
          <p>{volatility?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Beta</h4>
          <p>{beta?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Volume</h4>
          <p>{volume?.toLocaleString()}</p>
        </div>
        <div className="risk-metric">
          <h4>Turnover Ratio</h4>
          <p>{turnoverRatio?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Max Drawdown</h4>
          <p>{maxDrawdown?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Value at Risk (5%)</h4>
          <p>{valueAtRisk?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Sharpe Ratio</h4>
          <p>{sharpeRatio?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Sortino Ratio</h4>
          <p>{sortinoRatio?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Debt to Equity</h4>
          <p>{debtToEquity?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Current Ratio</h4>
          <p>{currentRatio?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Profit Margin</h4>
          <p>{profitMargin?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Return on Assets</h4>
          <p>{roa?.toFixed(2)}%</p>
        </div>
        <div className="risk-metric">
          <h4>Interest Coverage</h4>
          <p>{interestCoverage?.toFixed(2)}</p>
        </div>
        <div className="risk-metric">
          <h4>Altman Z-Score</h4>
          <p>{altmanZScore?.toFixed(2)}</p>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="stock-detail-tab-content">
            <div className="stock-detail-metrics-grid">
              {stockData && (
                <>
                  {renderMetricCard('Correlation_BTC', stockData.bci, 'bci')}
                  {renderMetricCard('Premium', stockData.vp, 'vp')}
                  {renderMetricCard('Risk', stockData.risk, 'risk')}
                  {renderMetricCard('News Sentiment', stockData.sentiment_rank, 'sentiment')}
                </>
              )}
            </div>

            <div className="stock-detail-basic-info">
              <div className="stock-detail-metric-row">
                <div className="stock-detail-metric">
                  <h3>Price</h3>
                  <div className="stock-detail-price-info">
                    <span className="stock-detail-value">${priceData?.current_price.toFixed(2)}</span>
                    <span className={`stock-detail-change ${Number(priceData?.current_return) >= 0 ? 'positive' : 'negative'}`}>
                      ({Number(priceData?.current_return).toFixed(2)}%)
                    </span>
                  </div>
                </div>

                <div className="stock-detail-metric">
                  <h3>Market Cap</h3>
                  <span className="stock-detail-value">{formatNumber(stockData.market_cap)}</span>
                </div>

                <div className="stock-detail-metric">
                  <h3>Volume</h3>
                  <div className="stock-detail-volume">
                    <div>Previous: {formatNumber(stockData.previous_volume)}</div>
                    <div>10D avg: {formatNumber(stockData.avg_10d_volume)}</div>
                  </div>
                </div>

                <div className="stock-detail-metric">
                  <h3>Sector</h3>
                  <span className="stock-detail-value">{stockData.sector}</span>
                </div>
              </div>

              {stockData.introduction && (
                <div className="stock-detail-introduction">
                  <h3>{t('introduction')}</h3>
                  <p>
                    {showFullIntro 
                      ? t(`companyIntroductions.${ticker}.description`)
                      : `${t(`companyIntroductions.${ticker}.description`).slice(0, 300)}...`
                    }
                  </p>
                  {t(`companyIntroductions.${ticker}.description`).length > 300 && (
                    <button 
                      className="stock-detail-show-more"
                      onClick={() => setShowFullIntro(!showFullIntro)}
                    >
                      {showFullIntro ? t('showLess') : t('showMore')}
                    </button>
                  )}
                </div>
              )}

              {renderPriceChart()}

              {renderBusinessActivities()}
            </div>
          </div>
        );
      case 'bci':
        return (
          <BciMetrics 
            bciData={bciData}
            priceRatioHistory={priceRatioHistory}
            balanceHistory={balanceHistory}
          />
        );
      case 'valuation':
        return <ValuationPremium data={valuationPremium} ticker={ticker} />;
      case 'risk':
        return (
          <div className="stock-detail-section">
            {riskData ? (
              <RiskMetrics riskData={riskData} />
            ) : (
              <div className="stock-detail-loading">Loading risk data...</div>
            )}
          </div>
        );
      case 'recommendation':
        return <StockRecommendation ticker={ticker} />;
      default:
        return null;
    }
  };

  if (loading) return <div className="stock-detail-loading">Loading...</div>;
  if (error) return <div className="stock-detail-error">Error: {error}</div>;
  if (!stockData || !priceData) return <div className="stock-detail-no-data">No data available</div>;

  return (
    <div className="stock-detail-container">
      <header className="stock-detail-header">
        <h1 className="stock-detail-title">{stockData.name} ({ticker})</h1>
      </header>

      <nav className="stock-detail-tab-navigation">
        <button 
          className={`stock-detail-tab-button ${activeTab === 'overview' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={`stock-detail-tab-button ${activeTab === 'bci' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('bci')}
        >
          Correlation_BTC
        </button>
        <button 
          className={`stock-detail-tab-button ${activeTab === 'valuation' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('valuation')}
        >
          Premium
        </button>
        <button 
          className={`stock-detail-tab-button ${activeTab === 'risk' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('risk')}
        >
          Risk
        </button>
        <button 
          className={`stock-detail-tab-button ${activeTab === 'recommendation' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('recommendation')}
        >
          Recommendation
        </button>
      </nav>

      {renderTabContent()}
    </div>
  );
};

export default StockDetail;