import React from 'react';
import './SectionTitleBar.css';

const SectionTitleBar = ({ title, id }) => {
  return (
    <div className="fin-section-title-wrapper">
      <span className="fin-section-indicator"></span>
      <h3 className="fin-section-title">
        {id ? (
          <a href={`#${id}`}>{title}</a>
        ) : (
          title
        )}
      </h3>
    </div>
  );
};

export default SectionTitleBar;