import React, { useState, useEffect } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { getStockRecommendationRatings } from '../../services/data_api';
import './StockRecommendation.css';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const RecommendationMeter = ({ value }) => {
  const safeValue = value ?? 1;
  
  return (
    <div className="stock-rec-meter">
      <ReactSpeedometer
        value={safeValue}
        minValue={1}
        maxValue={5}
        segments={5}
        currentValueText=""
        customSegmentLabels={[
          {
            text: "Strong Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Hold",
            position: "INSIDE",
            color: "#333",
            fontSize: "11px"
          },
          {
            text: "Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Strong Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          }
        ]}
        segmentColors={[
          "#D32F2F",
          "#FF5722",
          "#FDD835",
          "#4CAF50",
          "#2E7D32"
        ]}
        startAngle={180}
        endAngle={0}
        needleColor="#333"
        needleTransition="easeElastic"
        needleTransitionDuration={3000}
        needleHeightRatio={0.7}
        labelFontSize="11px"
        valueTextFontSize="0"
        width={400}
        height={200}
        paddingVertical={20}
        ringWidth={50}
        textColor="#666"
        paddingHorizontal={10}
        segmentSpacing={2}
        maxSegmentLabels={5}
        customSegmentStops={[1, 1.8, 2.6, 3.4, 4.2, 5]}
      />
    </div>
  );
};

const RatingBar = ({ value, maxValue = 5 }) => {
  if (value == null) {
    return <div className="stock-rec-rating-value">N/A</div>;
  }

  const percentage = (value / maxValue) * 100;
  
  return (
    <div className="stock-rec-rating-container">
      <div className="stock-rec-rating-track">
        <div 
          className="stock-rec-rating-fill" 
          style={{ 
            width: `${percentage}%`,
            background: `linear-gradient(90deg, #D32F2F, #FDD835 50%, #2E7D32)`
          }} 
        />
        <div className="stock-rec-rating-arrow" style={{ left: `${percentage}%` }}>
          <span className="stock-rec-arrow-head">▼</span>
        </div>
      </div>
      <div className="stock-rec-rating-value">{value.toFixed(2)}</div>
    </div>
  );
};

const TechnicalIndicator = ({ label, value, tooltip }) => {
  return (
    <div className="stock-rec-indicator-card">
      <div className="stock-rec-indicator-content">
        <Tooltip content={tooltip}>
          <span className="stock-rec-indicator-label">{label}</span>
        </Tooltip>
        <span className="stock-rec-indicator-value">
          {value?.toFixed(2) || 'N/A'}
        </span>
      </div>
    </div>
  );
};

const StockRecommendation = ({ ticker }) => {
  const { t } = useTranslation();
  const [recommendationData, setRecommendationData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getStockRecommendationRatings(ticker);
        setRecommendationData(data);
      } catch (error) {
        console.error('Error fetching recommendation data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (ticker) {
      fetchData();
    }
  }, [ticker]);

  if (loading) {
    return <div className="stock-recommendation-loading">Loading...</div>;
  }

  if (!recommendationData) {
    return <div className="stock-recommendation-error">Failed to load recommendation data</div>;
  }

  return (
    <div className="stock-rec-container">
      <div className="stock-rec-header">
        <div className="stock-rec-meter-container">
          <RecommendationMeter value={recommendationData.total_rating} />
        </div>
        
        <div className="stock-rec-ratings">
          <div className="stock-rec-rating-item">
            <Tooltip content={t('metrics.tooltips.modelRating')}>
              <div className="stock-rec-rating-label-wrapper">
                <span className="stock-rec-rating-label">Model Rating</span>
              </div>
            </Tooltip>
            <RatingBar value={recommendationData.model_rating} />
          </div>
          <div className="stock-rec-rating-item">
            <Tooltip content={t('metrics.tooltips.technicalRating')}>
              <div className="stock-rec-rating-label-wrapper">
                <span className="stock-rec-rating-label">Technical Rating</span>
              </div>
            </Tooltip>
            <RatingBar value={recommendationData.technical_rating} />
          </div>
          <div className="stock-rec-rating-item">
            <Tooltip content={t('metrics.tooltips.wallStreetRating')}>
              <div className="stock-rec-rating-label-wrapper">
                <span className="stock-rec-rating-label">Wall Street Rating</span>
              </div>
            </Tooltip>
            <RatingBar value={recommendationData.wall_street_rating} />
          </div>
          <div className="stock-rec-rating-item">
            <Tooltip content={t('metrics.tooltips.analystsRating')}>
              <div className="stock-rec-rating-label-wrapper">
                <span className="stock-rec-rating-label">Analysts Rating</span>
              </div>
            </Tooltip>
            <RatingBar value={recommendationData.analysts_rating} />
          </div>
        </div>
      </div>

      <div className="stock-rec-indicators">
        <div className="stock-rec-indicators-grid">
          <TechnicalIndicator 
            label="RSI" 
            value={recommendationData.rsi}
            tooltip={t('metrics.tooltips.rsi')}
          />
          <TechnicalIndicator 
            label="MACD" 
            value={recommendationData.macd}
            tooltip={t('metrics.tooltips.macd')}
          />
          <TechnicalIndicator 
            label="STOCH" 
            value={recommendationData.stoch_k}
            tooltip={t('metrics.tooltips.stoch')}
          />
          <TechnicalIndicator 
            label="ADX" 
            value={recommendationData.adx}
            tooltip={t('metrics.tooltips.adx')}
          />
          <TechnicalIndicator 
            label="CCI" 
            value={recommendationData.cci}
            tooltip={t('metrics.tooltips.cci')}
          />
          <TechnicalIndicator 
            label="ATR" 
            value={recommendationData.atr}
            tooltip={t('metrics.tooltips.atr')}
          />
          <TechnicalIndicator 
            label="ROC" 
            value={recommendationData.roc}
            tooltip={t('metrics.tooltips.roc')}
          />
          <TechnicalIndicator 
            label="Williams %R" 
            value={recommendationData.williams_r}
            tooltip={t('metrics.tooltips.williamsR')}
          />
        </div>
      </div>
    </div>
  );
};

export default StockRecommendation; 