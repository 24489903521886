import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from './PageLayout';
import './MainPage.css';

const MainPage = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userSubType = useSelector(state => state.subType);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const title = titleRef.current;
    const letters = title.querySelectorAll('.letter');
    const star = title.querySelector('.jumping-star');
    let currentIndex = 0;

    const animateLetters = () => {
      letters.forEach(letter => letter.classList.remove('active'));
      letters[currentIndex].classList.add('active');
      
      const activeLetterRect = letters[currentIndex].getBoundingClientRect();
      const titleRect = title.getBoundingClientRect();
      
      star.style.left = `${activeLetterRect.left - titleRect.left + activeLetterRect.width / 2}px`;
      star.style.top = `${activeLetterRect.top - titleRect.top - 40}px`; // 星星在字母上方
      
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % letters.length;
        requestAnimationFrame(animateLetters);
      }, 300);
    };

    animateLetters();
  }, []);

  const panels = [
    { 
      id: 'bitcoin', 
      title: t('btcRelatedStocks'),
      link: '/products',
      sections: [
        {
          title: t('seizeOpportunities'),
          description: t('seizeOpportunitiesDescription'),
          tag: 'free'
        },
        {
          title: t('stayAhead'),
          description: t('stayAheadDescription'),
          tag: 'free'
        },
        {
          title: t('premiumBenefits'),
          description: t('premiumBenefitsDescription'),
          tag: 'pro'
        }
      ],
      icon: '₿'
    },
    { 
      id: 'longterm', 
      title: t('longTermStocks'),
      comingSoon: true,
      sections: [
        {
          title: t('buildWealthDescription'),
          description: t('buildWealthFullDescription'),
          tag: 'free'
        },
        {
          title: t('analyzeStocks'),
          description: t('analyzeStocksDescription'),
          tag: 'free'
        },
        {
          title: t('longTermPremiumBenefits'),
          description: t('longTermPremiumBenefitsDescription'),
          tag: 'pro'
        }
      ],
      icon: '📈'
    }
  ];

  return (
    <PageLayout>
      <div className="main-landing-content">
        <div className="hero-section">
          <div className="hero-content">
            <h1 ref={titleRef} className="animated-title">
              {'Pick Your Stocks'.split(' ').map((word, wordIndex) => (
                <React.Fragment key={wordIndex}>
                  {word.split('').map((char, charIndex) => (
                    <span key={charIndex} className="letter">{char}</span>
                  ))}
                  {wordIndex < 2 && <span className="space"> </span>}
                </React.Fragment>
              ))}
              <span className="jumping-star">⭐</span>
            </h1>
            <p className="slogan">{t('slogan')}</p>
            <Link to="/products" className="cta-button">
              {t('getStarted')}
            </Link>
          </div>
          
          <div className="wave-divider">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2880 320" preserveAspectRatio="none">
              <path className="wave1" d="M0,192L80,186.7C160,181,320,171,480,181.3C640,192,800,224,960,224C1120,224,1280,192,1440,181.3C1600,171,1760,181,1920,192C2080,203,2240,213,2400,208C2560,203,2720,192,2800,186.7L2880,181L2880,320L2800,320C2720,320,2560,320,2400,320C2240,320,2080,320,1920,320C1760,320,1600,320,1440,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" fill="rgba(255,255,255,0.1)" />
              <path className="wave2" d="M0,256L80,240C160,224,320,192,480,202.7C640,213,800,267,960,272C1120,277,1280,235,1440,224C1600,213,1760,235,1920,245.3C2080,256,2240,256,2400,240C2560,224,2720,192,2800,176L2880,160L2880,320L2800,320C2720,320,2560,320,2400,320C2240,320,2080,320,1920,320C1760,320,1600,320,1440,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" fill="rgba(255,255,255,0.2)" />
              <path className="wave3" d="M0,128L80,144C160,160,320,192,480,213.3C640,235,800,245,960,234.7C1120,224,1280,192,1440,181.3C1600,171,1760,181,1920,192C2080,203,2240,213,2400,208C2560,203,2720,192,2800,186.7L2880,181L2880,320L2800,320C2720,320,2560,320,2400,320C2240,320,2080,320,1920,320C1760,320,1600,320,1440,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" fill="rgba(255,255,255,0.3)" />
            </svg>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="interactive-panels">
            {panels.map((panel) => (
              <div 
                key={panel.id} 
                className={`panel ${panel.link ? 'clickable' : ''}`}
                onClick={() => panel.link && navigate(panel.link)}
              >
                <div className="panel-icon">{panel.icon}</div>
                <div className="panel-header">
                  <h2 className="panel-title">{panel.title}</h2>
                  {panel.comingSoon && <span className="coming-soon-tag">{t('comingSoon')}</span>}
                </div>
                <div className="panel-description">
                  {panel.sections.map((section, index) => (
                    <div key={index} className={`section ${section.tag}-section`}>
                      <h3 className={`section-title ${section.tag}-title`}>{section.title}</h3>
                      <p className={`section-description ${section.tag}-description`}>{section.description}</p>
                      <span className={`tag ${section.tag}-tag`}>
                        {section.tag.toUpperCase()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MainPage;
