export const CacheService = {
    // 缓存时间配置
    TTL: {
      userData: 5 * 60 * 1000,     // 5分钟
      subscription: 5 * 60 * 1000  // 5分钟
    },
  
    set(key, data) {
      const item = {
        data,
        timestamp: Date.now()
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
  
    get(key) {
      const item = localStorage.getItem(key);
      if (!item) return null;
  
      const { data, timestamp } = JSON.parse(item);
      const ttl = this.TTL[key] || 5 * 60 * 1000;  // 默认5分钟
  
      if (Date.now() - timestamp > ttl) {
        localStorage.removeItem(key);
        return null;
      }
  
      return data;
    }
  }; 