import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { 
  getTopAssetsDetails, 
  getTopMarketCapsTickersList,
  getTopMarketCapsHistory 
} from '../services/data_api';
import dayjs from 'dayjs';
import './TopAssetsComparison.css';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

// 预定义20种区分度高的颜色
const COLORS = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
  '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  '#393b79', '#637939', '#8c6d31', '#843c39', '#7b4173',
  '#5254a3', '#6b6ecf', '#b5cf6b', '#cedb9c', '#9c9ede'
];

const TopAssetsComparison = () => {
  const { t } = useTranslation();
  const [assetsData, setAssetsData] = useState([]);
  const [tickersList, setTickersList] = useState([]);
  const [historicalData, setHistoricalData] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState('ALL');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. 获取股票列表和资产详情
        const [tickersResponse, assetsResponse] = await Promise.all([
          getTopMarketCapsTickersList(),
          getTopAssetsDetails()
        ]);
        
        if (tickersResponse?.data) {
          setTickersList(tickersResponse.data);
        }
        
        if (assetsResponse?.data) {
          setAssetsData(assetsResponse.data);
        }

        // 2. 获取每个股票的历史数据
        const historicalResponses = await Promise.all(
          tickersResponse.data.map(({ ticker }) => getTopMarketCapsHistory(ticker))
        );

        // 3. 处理历史数据
        const processedHistoricalData = {};
        historicalResponses.forEach((response, index) => {
          const ticker = tickersResponse.data[index].ticker;
          processedHistoricalData[ticker] = response.data;
        });

        setHistoricalData(processedHistoricalData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getChartOption = () => {
    if (!historicalData || Object.keys(historicalData).length === 0) return {};

    // 获取所有日期
    const allDates = [...new Set(
      Object.values(historicalData)
        .flat()
        .map(item => item.date)
    )].sort();

    // 根据选择的时间范围过滤数据
    const filteredDates = selectedRange === 'ALL' 
      ? allDates
      : allDates.filter(date => {
          const cutoffDate = new Date();
          cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === selectedRange).days);
          return new Date(date) >= cutoffDate;
        });

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
        formatter: function(params) {
          const date = params[0]?.axisValue || '';
          let result = `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>`;
          
          params.forEach((param, index) => {
            const color = COLORS[index % COLORS.length];
            const value = param.value;
            result += `<div style="color: ${color}; padding: 3px 0;">
              <span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 8px;"></span>
              ${param.seriesName}: ${value ? `$${(value/1000).toFixed(2)}T` : 'N/A'}
            </div>`;
          });
          
          return result + '</div>';
        }
      },
      legend: {
        data: tickersList.map(item => item.name),
        top: '10%',
        left: 'center',
        textStyle: { fontSize: 14 }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '25%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredDates,
        axisLabel: {
          rotate: 45,
          interval: 'auto'
        }
      },
      yAxis: {
        type: 'value',
        name: 'Market Cap (T)',
        nameTextStyle: {
          fontSize: 16,
          fontWeight: 500,
          padding: [0, 0, 0, 50]
        },
        axisLabel: {
          formatter: value => `$${(value/1000).toFixed(1)}T`,
          fontSize: 14
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5
        }
      ],
      series: tickersList.map((item, index) => ({
        name: item.name,
        type: 'line',
        data: filteredDates.map(date => {
          const dataPoint = historicalData[item.ticker]?.find(d => d.date === date);
          return dataPoint?.market_cap || null;
        }),
        smooth: true,
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: {
          width: 2,
          color: COLORS[index % COLORS.length]
        },
        itemStyle: {
          color: COLORS[index % COLORS.length]
        },
        connectNulls: true
      }))
    };
  };

  const getDisplayData = () => {
    if (!assetsData?.length) return [];
    
    const bitcoinRank = assetsData.findIndex(item => item.name === 'Bitcoin') + 1;
    
    if (showAll) {
      return assetsData.map((asset, index) => ({
        ...asset,
        actualRank: index + 1,
        ...(asset.name === 'Bitcoin' ? { bitcoinRank } : {})
      }));
    }
    
    const topSix = assetsData.slice(0, 6);
    const bitcoin = assetsData.find(item => item.name === 'Bitcoin');
    
    return [
      ...topSix,
      { id: 'ellipsis', name: '...', market_cap_value: null },
      { ...bitcoin, actualRank: bitcoinRank }
    ].filter(Boolean);
  };

  if (loading) {
    return (
      <div className="top-assets-comparison">
        <div className="loading-state">{t('loading')}</div>
      </div>
    );
  }

  return (
    <div className="top-assets-comparison">
      <div className="comparison-container">
        <div className="table-section">
          <div className="title-section">
            <h3>{t('Top Assets')}</h3>
          </div>
          <div className="table-container">
            <table className="assets-table">
              <thead>
                <tr>
                  <th>{t('Rank')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Market Cap')}</th>
                </tr>
              </thead>
              <tbody>
                {getDisplayData().map((asset, index) => (
                  <tr 
                    key={asset.id || index}
                    className={asset.id === 'ellipsis' ? 'ellipsis-row' : ''}
                    onClick={asset.id === 'ellipsis' ? () => setShowAll(!showAll) : undefined}
                  >
                    <td>
                      {asset.id === 'ellipsis' 
                        ? '' 
                        : asset.name === 'Bitcoin' 
                          ? asset.actualRank || asset.bitcoinRank 
                          : showAll 
                            ? asset.actualRank 
                            : index + 1}
                    </td>
                    <td>{asset.name}</td>
                    <td className="market-cap-value">
                      {asset.id === 'ellipsis' ? '' : 
                        `$${(asset.market_cap_value / 1000).toFixed(2)}T`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button 
            className="show-more-btn"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? t('Show Less') : t('Show More')}
          </button>
        </div>

        <div className="chart-section">
          <div className="top-assets-chart-header">
            <div className="top-assets-range-selector">
              {TIME_RANGES.map(range => (
                <button
                  key={range.label}
                  className={`top-assets-range-btn ${selectedRange === range.label ? 'active' : ''}`}
                  onClick={() => setSelectedRange(range.label)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
          <ReactECharts 
            option={getChartOption()} 
            style={{ height: '100%' }}
            notMerge={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TopAssetsComparison;