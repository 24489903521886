import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './AboutPage.css';
import { FaInfoCircle, FaLock } from 'react-icons/fa';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <div className="about-page">
        <div className="content">
          <div className="mission-section">
            <div className="section-decorator"></div>
            <h2 className="about-section-title">Our Mission</h2>
            <p className="section-content">
              We believe that professional financial services should be accessible to everyone, not just experienced investors. Our mission is to empower amateur investors by using cutting-edge technology, such as AI, to simplify complex financial information into actionable and efficient insights.
            </p>
            <p className="section-content">
              With a focus on Simplify and Effective, we transform overwhelming financial data into clear, concise metrics that save you time and reduce costs. Whether you're a beginner or a seasoned investor, our platform provides the tools and insights you need to make smarter investment decisions with confidence.
            </p>
          </div>

          <div className="services-section">
            <div className="section-decorator"></div>
            <h2 className="about-section-title">Our Services</h2>
            <p className="section-content">
              Our platform is designed to simplify the investment journey and provide you with actionable insights across multiple markets. Here's what we offer:
            </p>
            <div className="services-grid">
              <div className="service-card">
                <h3 className="service-title">Bitcoin and Cryptocurrency Analysis</h3>
                <ul className="service-list">
                  <li>Real-time data and market trends to help you navigate the fast-paced world of cryptocurrencies.</li>
                  <li>In-depth analysis of Bitcoin-related stocks and ETFs for diversified investment opportunities.</li>
                </ul>
              </div>
              <div className="service-card">
                <h3 className="service-title">US Stock Market Insights</h3>
                <ul className="service-list">
                  <li>Recommendations tailored for both long-term and short-term investment strategies.</li>
                  <li>Comprehensive market indicators to help you understand trends and make smarter decisions.</li>
                </ul>
              </div>
              <div className="service-card">
                <h3 className="service-title">Personalized Tools</h3>
                <ul className="service-list">
                  <li>Event Calendar: Track key financial events like earnings reports and economic announcements.</li>
                  <li>Stock Pool Filter: Identify stocks that match your investment criteria with ease.</li>
                  <li>Risk Assessment Models: Evaluate portfolio risks and optimize your strategies.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="why-choose-section">
            <div className="section-decorator"></div>
            <h2 className="about-section-title">Why Choose Us?</h2>
            <p className="section-content">
              We are committed to making investing Simplify and Effective. By turning complex financial data into intuitive insights, we save you time and effort while providing professional-grade tools at an affordable cost. With our platform, you can focus on what matters most: making confident, informed decisions.
            </p>
          </div>

          <div className="contact-section">
            <div className="section-decorator"></div>
            <h2 className="about-section-title">Contact Us</h2>
            <div className="contact-content">
              <p className="section-content">
                Have questions or feedback? We'd love to hear from you. Reach out to us at:
              </p>
              <a href="mailto:FinStockInsight@outlook.com" className="email-link">
                FinStockInsight@outlook.com
              </a>
            </div>
          </div>

          <div className="disclaimer-privacy-section" id="privacy-section">
            <div className="disclaimer-privacy-container">
              <div className="disclaimer-box">
                <div className="decorative-shape shape-1"></div>
                <FaInfoCircle className="info-icon" />
                <h2 className="fancy-title">
                  <span>Disclaimer</span>
                </h2>
                <p className="fancy-content">
                  The information provided on this website is for informational purposes only and does not constitute investment, financial, or trading advice. We do not recommend buying, selling, or holding any financial instruments. All users should conduct their own research and consult a licensed financial advisor before making investment decisions. While we strive for accuracy, we do not guarantee the completeness or reliability of the information provided. Use this website and its content at your own risk.
                </p>
              </div>

              <div className="privacy-box">
                <div className="decorative-shape shape-2"></div>
                <FaLock className="info-icon" />
                <h2 className="fancy-title">
                  <span>Privacy Policy</span>
                </h2>
                <p className="fancy-content">
                  We value your privacy. Any personal data collected, such as email addresses or usage data, is used solely to improve our services. We may use cookies to enhance your experience, but you can disable them in your browser settings. Your data will never be shared with third parties without your consent, except as required by law. By using our website, you agree to our data collection practices, which are designed to ensure security and transparency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutPage;
