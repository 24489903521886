export const MARKET_CAP_CATEGORIES = {
  SMALL: {
    title: 'Small Market Cap',
    stocks: ['BYON', 'SMLR', 'FRMO', 'CAN', 'HIVE', 'EXOD', 'BTBT', 'BITF', 'BTDR', 
             'HUT', 'IREN', 'CIFR', 'WULF', 'CLSK', 'CORZ', 'RIOT', 'MARA']
  },
  MEDIUM: {
    title: 'Medium Market Cap',
    stocks: ['GLOB', 'SOFI', 'RDDT', 'HOOD', 'SQ', 'MSTR', 'COIN', 'CME', 'PYPL']
  },
  LARGE: {
    title: 'Large Market Cap',
    stocks: ['SHOP', 'MELI', 'BLK', 'AMD', 'MA', 'V', 'TSLA', 'META', 'NVDA']
  }
}; 