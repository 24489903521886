import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getEtfNetInflowLatest, getBtcPriceReturn, formatNumber, getEtfNetInflowHistory } from '../services/data_api';
import ReactECharts from 'echarts-for-react';
import './EtfNetInflow.css';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const formatAxisLabel = (value) => {
  if (Math.abs(value) >= 1e12) {
    return `$${(value / 1e12).toFixed(1)}T`;
  } else if (Math.abs(value) >= 1e9) {
    return `$${(value / 1e9).toFixed(1)}B`;
  } else if (Math.abs(value) >= 1e6) {
    return `$${(value / 1e6).toFixed(1)}M`;
  } else if (Math.abs(value) >= 1e3) {
    return `$${(value / 1e3).toFixed(1)}K`;
  }
  return `$${value}`;
};

const EtfNetInflow = () => {
  const { t } = useTranslation();
  const [etfData, setEtfData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [etfResponse, priceResponse, historyResponse] = await Promise.all([
          getEtfNetInflowLatest(),
          getBtcPriceReturn(),
          getEtfNetInflowHistory()
        ]);
        
        setEtfData(etfResponse);
        setPriceData(priceResponse?.data?.[0]);
        setHistoryData(historyResponse?.data);
        setLoading(false);
      } catch (err) {
        console.error('Fetch Error:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getEtfChartOption = () => {
    if (!historyData || !Array.isArray(historyData)) {
      return { title: { text: 'No data available' } };
    }

    const filterDataByRange = (data, range) => {
      if (range === 'ALL') return data;
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === range).days);
      return data.filter(item => new Date(item.date) >= cutoffDate);
    };

    const validData = filterDataByRange(historyData, selectedRange);

    // 使用固定的放大倍数，保持原有波动比例
    const scaleFactor = 600000 // 可以根据需要调整这个数值，使图表看起来合适

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
        formatter: function(params) {
          const date = params[0].axisValue;
          const inflow = params[0].data;
          const assets = params[1].data;
          const btcPrice = params[2]?.data / scaleFactor; // 添加 BTC 价格

          return `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
            <div style="color: ${inflow >= 0 ? '#34a853' : '#ea4335'}">
              Net Inflow: ${inflow.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </div>
            <div style="color: #1a73e8">
              Total Assets: ${assets.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </div>
            <div style="color: #ff9800">
              BTC Price: ${btcPrice?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </div>
          </div>`;
        }
      },
      legend: {
        data: [
          {
            name: 'Daily Net Inflow',
            icon: 'rect',
            itemStyle: {
              color: {
                type: 'linear',
                x: 0, y: 0, x2: 1, y2: 0,
                colorStops: [{
                  offset: 0,
                  color: '#34a853'
                }, {
                  offset: 0.5,
                  color: '#34a853'
                }, {
                  offset: 0.5,
                  color: '#ea4335'
                }, {
                  offset: 1,
                  color: '#ea4335'
                }]
              }
            }
          },
          {
            name: 'Total Assets',
            icon: 'rect',
            itemStyle: {
              color: '#1a73e8'
            }
          },
          {
            name: 'BTC Price',
            icon: 'rect',
            itemStyle: {
              color: '#ff9800'
            }
          }
        ],
        top: 0,
        textStyle: {
          fontSize: 14
        },
        itemWidth: 24,
        itemHeight: 14
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5,
          borderColor: '#ddd',
          fillerColor: 'rgba(26, 115, 232, 0.1)',
          handleStyle: { color: '#1a73e8' }
        }
      ],
      grid: {
        left: '8%',
        right: '8%',
        bottom: '15%',
        top: '8%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: validData.map(item => item.date),
        axisLabel: {
          rotate: 45,
          color: '#666',
          fontSize: 11
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Daily Net Inflow',
          nameLocation: 'end',
          nameGap: 20,
          nameRotate: 0,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#34a853',
            padding: [0, 0, 8, 0]
          },
          position: 'right',
          axisLine: { 
            lineStyle: { color: '#34a853' } 
          },
          axisLabel: { 
            formatter: (value) => formatAxisLabel(value),
            fontSize: 12,
            margin: 16
          }
        },
        {
          type: 'value',
          name: 'Total Assets',
          nameLocation: 'end',
          nameGap: 20,
          nameRotate: 0,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: '#1a73e8',
            padding: [0, 0, 8, 0]
          },
          position: 'left',
          axisLine: { 
            lineStyle: { color: '#1a73e8' } 
          },
          axisLabel: {
            formatter: (value) => formatAxisLabel(value),
            fontSize: 12,
            margin: 16
          }
        }
      ],
      series: [
        {
          name: 'Daily Net Inflow',
          type: 'bar',
          data: validData.map(item => item.daily_net_inflow),
          itemStyle: {
            color: (params) => {
              return params.data >= 0 ? '#34a853' : '#ea4335';
            }
          }
        },
        {
          name: 'Total Assets',
          type: 'line',
          yAxisIndex: 1,
          data: validData.map(item => item.total_assets),
          smooth: true,
          symbol: 'none',
          lineStyle: { width: 2, color: '#1a73e8' },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0, y: 0, x2: 0, y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26, 115, 232, 0.2)'
              }, {
                offset: 1,
                color: 'rgba(26, 115, 232, 0)'
              }]
            }
          }
        },
        {
          name: 'BTC Price',
          type: 'line',
          yAxisIndex: 1,
          data: validData.map(item => item.btc_price * scaleFactor),
          smooth: true,
          symbol: 'none',
          lineStyle: { 
            width: 2,
            color: '#ff9800'
          }
        }
      ]
    };
  };

  if (loading) return <div className="etf-loading-state">{t('loading')}</div>;
  if (error) return <div className="etf-error-state">{t('error')}: {error}</div>;
  if (!etfData || !priceData) return <div className="etf-no-data-state">{t('noData')}</div>;

  return (
    <div className="etf-inflow-component">
      <div className="etf-header">
        <div className="etf-title">
          <img src="/images/Bitcoinlogo.png" alt="BTC" className="etf-btc-logo" />
          <h1>Bitcoin. (BTC)</h1>
        </div>
      </div>

      <div className="etf-metrics-grid">
        {/* Total Net Assets */}
        <div className="etf-metric-section">
          <h3>Total Net Assets</h3>
          <div className="etf-data-value">
            ${formatNumber(etfData.total_assets)}
          </div>
        </div>

        {/* Daily Net Inflow */}
        <div className="etf-metric-section">
          <h3>Daily Net Inflow</h3>
          <div className="etf-data-value">
            ${formatNumber(etfData.daily_net_inflow)}
          </div>
        </div>

        {/* Bitcoin Price */}
        <div className="etf-metric-section">
          <h3>Bitcoin Price</h3>
          <div className="etf-price-container">
            <div className="etf-price-value">
              ${priceData.current_price.toLocaleString()}
            </div>
            <div className={`etf-price-change ${Number(priceData.return_to_previous) >= 0 ? 'positive' : 'negative'}`}>
              ({Number(priceData.return_to_previous).toFixed(2)}%)
            </div>
          </div>
        </div>
      </div>

      {/* New Content Sections */}
      <div className="etf-content-sections">
        <section className="etf-content-section">
          <h2 className="etf-section-title">{t('etfInsights.marketDemand.title')}</h2>
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.marketDemand.point1') }} />
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.marketDemand.point2') }} />
        </section>

        <section className="etf-content-section">
          <h2 className="etf-section-title">{t('etfInsights.institutional.title')}</h2>
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.institutional.point1') }} />
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.institutional.point2') }} />
        </section>

        <section className="etf-content-section">
          <h2 className="etf-section-title">{t('etfInsights.chartReading.title')}</h2>
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.chartReading.point1') }} />
          <p className="etf-section-text" 
             dangerouslySetInnerHTML={{ __html: t('etfInsights.chartReading.point2') }} />
        </section>
      </div>

      {/* Chart Section */}
      <div className="etf-chart-section">
        <div className="etf-chart-header">
          <h2>BTC ETF Data</h2>
          <div className="etf-time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`etf-time-range-button ${selectedRange === range.label ? 'active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getEtfChartOption()} 
          style={{ height: '500px' }}
          className="etf-chart"
        />
      </div>
    </div>
  );
};

export default EtfNetInflow; 